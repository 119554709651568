/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ImageVariantsResponse } from './ImageVariantsResponse';
import {
    ImageVariantsResponseFromJSON,
    ImageVariantsResponseFromJSONTyped,
    ImageVariantsResponseToJSON,
} from './ImageVariantsResponse';

/**
 * 
 * @export
 * @interface GetPostAuthorResponse
 */
export interface GetPostAuthorResponse {
    /**
     * 
     * @type {string}
     * @memberof GetPostAuthorResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetPostAuthorResponse
     */
    userId: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPostAuthorResponse
     */
    isVerified: boolean;
    /**
     * 
     * @type {ImageVariantsResponse}
     * @memberof GetPostAuthorResponse
     */
    profilePicture?: ImageVariantsResponse;
    /**
     * 
     * @type {string}
     * @memberof GetPostAuthorResponse
     */
    slug: string;
}

/**
 * Check if a given object implements the GetPostAuthorResponse interface.
 */
export function instanceOfGetPostAuthorResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "isVerified" in value;
    isInstance = isInstance && "slug" in value;

    return isInstance;
}

export function GetPostAuthorResponseFromJSON(json: any): GetPostAuthorResponse {
    return GetPostAuthorResponseFromJSONTyped(json, false);
}

export function GetPostAuthorResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPostAuthorResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'userId': json['userId'],
        'isVerified': json['isVerified'],
        'profilePicture': !exists(json, 'profilePicture') ? undefined : ImageVariantsResponseFromJSON(json['profilePicture']),
        'slug': json['slug'],
    };
}

export function GetPostAuthorResponseToJSON(value?: GetPostAuthorResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'userId': value.userId,
        'isVerified': value.isVerified,
        'profilePicture': ImageVariantsResponseToJSON(value.profilePicture),
        'slug': value.slug,
    };
}

