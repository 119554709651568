/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetChannelResponse } from './GetChannelResponse';
import {
    GetChannelResponseFromJSON,
    GetChannelResponseFromJSONTyped,
    GetChannelResponseToJSON,
} from './GetChannelResponse';
import type { ImageVariantsResponse } from './ImageVariantsResponse';
import {
    ImageVariantsResponseFromJSON,
    ImageVariantsResponseFromJSONTyped,
    ImageVariantsResponseToJSON,
} from './ImageVariantsResponse';

/**
 * 
 * @export
 * @interface GetUserInfoResponse
 */
export interface GetUserInfoResponse {
    /**
     * 
     * @type {number}
     * @memberof GetUserInfoResponse
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof GetUserInfoResponse
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserInfoResponse
     */
    auth0Id: string;
    /**
     * 
     * @type {ImageVariantsResponse}
     * @memberof GetUserInfoResponse
     */
    profilePicture?: ImageVariantsResponse;
    /**
     * 
     * @type {string}
     * @memberof GetUserInfoResponse
     */
    twitterUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserInfoResponse
     */
    facebookUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserInfoResponse
     */
    instagramUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserInfoResponse
     */
    youtubeUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserInfoResponse
     */
    discordUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserInfoResponse
     */
    tikTokUrl?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserInfoResponse
     */
    canChangeDisplayName?: boolean;
    /**
     * 
     * @type {GetChannelResponse}
     * @memberof GetUserInfoResponse
     */
    streamerChannel: GetChannelResponse;
    /**
     * 
     * @type {number}
     * @memberof GetUserInfoResponse
     */
    notificationCount: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserInfoResponse
     */
    isContentCreator: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetUserInfoResponse
     */
    subscriptionPrice?: number;
}

/**
 * Check if a given object implements the GetUserInfoResponse interface.
 */
export function instanceOfGetUserInfoResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "displayName" in value;
    isInstance = isInstance && "auth0Id" in value;
    isInstance = isInstance && "streamerChannel" in value;
    isInstance = isInstance && "notificationCount" in value;
    isInstance = isInstance && "isContentCreator" in value;

    return isInstance;
}

export function GetUserInfoResponseFromJSON(json: any): GetUserInfoResponse {
    return GetUserInfoResponseFromJSONTyped(json, false);
}

export function GetUserInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUserInfoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'displayName': json['displayName'],
        'auth0Id': json['auth0Id'],
        'profilePicture': !exists(json, 'profilePicture') ? undefined : ImageVariantsResponseFromJSON(json['profilePicture']),
        'twitterUrl': !exists(json, 'twitterUrl') ? undefined : json['twitterUrl'],
        'facebookUrl': !exists(json, 'facebookUrl') ? undefined : json['facebookUrl'],
        'instagramUrl': !exists(json, 'instagramUrl') ? undefined : json['instagramUrl'],
        'youtubeUrl': !exists(json, 'youtubeUrl') ? undefined : json['youtubeUrl'],
        'discordUrl': !exists(json, 'discordUrl') ? undefined : json['discordUrl'],
        'tikTokUrl': !exists(json, 'tikTokUrl') ? undefined : json['tikTokUrl'],
        'canChangeDisplayName': !exists(json, 'canChangeDisplayName') ? undefined : json['canChangeDisplayName'],
        'streamerChannel': GetChannelResponseFromJSON(json['streamerChannel']),
        'notificationCount': json['notificationCount'],
        'isContentCreator': json['isContentCreator'],
        'subscriptionPrice': !exists(json, 'subscriptionPrice') ? undefined : json['subscriptionPrice'],
    };
}

export function GetUserInfoResponseToJSON(value?: GetUserInfoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'displayName': value.displayName,
        'auth0Id': value.auth0Id,
        'profilePicture': ImageVariantsResponseToJSON(value.profilePicture),
        'twitterUrl': value.twitterUrl,
        'facebookUrl': value.facebookUrl,
        'instagramUrl': value.instagramUrl,
        'youtubeUrl': value.youtubeUrl,
        'discordUrl': value.discordUrl,
        'tikTokUrl': value.tikTokUrl,
        'canChangeDisplayName': value.canChangeDisplayName,
        'streamerChannel': GetChannelResponseToJSON(value.streamerChannel),
        'notificationCount': value.notificationCount,
        'isContentCreator': value.isContentCreator,
        'subscriptionPrice': value.subscriptionPrice,
    };
}

