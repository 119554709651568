import { useAuth0 } from '@auth0/auth0-react'
import { useCallback } from 'react'
import { shouldRedirectToLogin } from './utils'

export const useToken = () => {
  const { getAccessTokenSilently, user, isAuthenticated, loginWithRedirect } =
    useAuth0()

  const getToken = useCallback(async () => {
    if (!isAuthenticated) return undefined

    try {
      const token = await getAccessTokenSilently({
        cacheMode: user?.email_verified ? 'on' : 'off',
      })

      return token
    } catch (error) {
      // awaiting the loginWithRedirect won't do anything here
      if (shouldRedirectToLogin(error)) loginWithRedirect()

      console.error('Error fetching access token:', error)
      throw new Error('Unable to fetch access token.')
    }
  }, [isAuthenticated, getAccessTokenSilently, user, loginWithRedirect])

  return { getToken }
}
