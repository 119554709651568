import { ErrorCodes, ModelError } from '../client'
import { LanguageKey } from '../shared/stores/useLanguage'

const defaultErrorCode: LanguageKey = 'errors.general.technicalError'

const errorCodeMap: Record<ErrorCodes | string, LanguageKey> = {
  TECHNICAL_ERROR: defaultErrorCode,
  REQUEST_VALIDATION_ERROR: 'errors.general.validationError',

  DISPLAYNAME_ALREADY_EXISTS: 'errors.specific.usernameAlreadyExists',
}

export class ApiError extends Error {
  public error: ModelError
  public message: LanguageKey

  constructor(error: ModelError) {
    const message = errorCodeMap[error.code || ''] ?? defaultErrorCode
    super(message)
    this.message = message
    this.error = error
  }
}

export const getErrorMessage = (e: unknown): LanguageKey =>
  e instanceof ApiError ? e.message : defaultErrorCode
