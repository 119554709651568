/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetChannelPromoPanelResponse,
} from '../models/index';
import {
    GetChannelPromoPanelResponseFromJSON,
    GetChannelPromoPanelResponseToJSON,
} from '../models/index';

export interface PromopanelChannelIdPromoPanelsGetRequest {
    channelId: string;
}

export interface PromopanelPromoPanelsPanelIdDeleteRequest {
    panelId: string;
}

export interface PromopanelPromoPanelsPanelIdGetRequest {
    panelId: string;
}

export interface PromopanelPromoPanelsPanelIdPutRequest {
    panelId: string;
    title?: string;
    description?: string;
    image?: Blob;
    imageLinkUrl?: string;
}

export interface PromopanelPromoPanelsPostRequest {
    title?: string;
    description?: string;
    image?: Blob;
    imageLinkUrl?: string;
}

/**
 * 
 */
export class PromoPanelApi extends runtime.BaseAPI {

    /**
     * Retrieves a list of all promo panels associated with the specified channel.
     */
    async promopanelChannelIdPromoPanelsGetRaw(requestParameters: PromopanelChannelIdPromoPanelsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetChannelPromoPanelResponse>>> {
        if (requestParameters.channelId === null || requestParameters.channelId === undefined) {
            throw new runtime.RequiredError('channelId','Required parameter requestParameters.channelId was null or undefined when calling promopanelChannelIdPromoPanelsGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/promopanel/{channelId}/promo-panels`.replace(`{${"channelId"}}`, encodeURIComponent(String(requestParameters.channelId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetChannelPromoPanelResponseFromJSON));
    }

    /**
     * Retrieves a list of all promo panels associated with the specified channel.
     */
    async promopanelChannelIdPromoPanelsGet(requestParameters: PromopanelChannelIdPromoPanelsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetChannelPromoPanelResponse>> {
        const response = await this.promopanelChannelIdPromoPanelsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes an existing promo panel within a channel. Only the channel owner is authorized to delete the promo panel.
     */
    async promopanelPromoPanelsPanelIdDeleteRaw(requestParameters: PromopanelPromoPanelsPanelIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.panelId === null || requestParameters.panelId === undefined) {
            throw new runtime.RequiredError('panelId','Required parameter requestParameters.panelId was null or undefined when calling promopanelPromoPanelsPanelIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/promopanel/promo-panels/{panelId}`.replace(`{${"panelId"}}`, encodeURIComponent(String(requestParameters.panelId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes an existing promo panel within a channel. Only the channel owner is authorized to delete the promo panel.
     */
    async promopanelPromoPanelsPanelIdDelete(requestParameters: PromopanelPromoPanelsPanelIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.promopanelPromoPanelsPanelIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Retrieves detailed information about a specific promo panel by its ID within a channel.
     */
    async promopanelPromoPanelsPanelIdGetRaw(requestParameters: PromopanelPromoPanelsPanelIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetChannelPromoPanelResponse>> {
        if (requestParameters.panelId === null || requestParameters.panelId === undefined) {
            throw new runtime.RequiredError('panelId','Required parameter requestParameters.panelId was null or undefined when calling promopanelPromoPanelsPanelIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/promopanel/promo-panels/{panelId}`.replace(`{${"panelId"}}`, encodeURIComponent(String(requestParameters.panelId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetChannelPromoPanelResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves detailed information about a specific promo panel by its ID within a channel.
     */
    async promopanelPromoPanelsPanelIdGet(requestParameters: PromopanelPromoPanelsPanelIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetChannelPromoPanelResponse> {
        const response = await this.promopanelPromoPanelsPanelIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates an existing promo panel within a channel. Only the channel owner is authorized to update the promo panel.
     */
    async promopanelPromoPanelsPanelIdPutRaw(requestParameters: PromopanelPromoPanelsPanelIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetChannelPromoPanelResponse>> {
        if (requestParameters.panelId === null || requestParameters.panelId === undefined) {
            throw new runtime.RequiredError('panelId','Required parameter requestParameters.panelId was null or undefined when calling promopanelPromoPanelsPanelIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.title !== undefined) {
            formParams.append('Title', requestParameters.title as any);
        }

        if (requestParameters.description !== undefined) {
            formParams.append('Description', requestParameters.description as any);
        }

        if (requestParameters.image !== undefined) {
            formParams.append('Image', requestParameters.image as any);
        }

        if (requestParameters.imageLinkUrl !== undefined) {
            formParams.append('ImageLinkUrl', requestParameters.imageLinkUrl as any);
        }

        const response = await this.request({
            path: `/promopanel/promo-panels/{panelId}`.replace(`{${"panelId"}}`, encodeURIComponent(String(requestParameters.panelId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetChannelPromoPanelResponseFromJSON(jsonValue));
    }

    /**
     * Updates an existing promo panel within a channel. Only the channel owner is authorized to update the promo panel.
     */
    async promopanelPromoPanelsPanelIdPut(requestParameters: PromopanelPromoPanelsPanelIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetChannelPromoPanelResponse> {
        const response = await this.promopanelPromoPanelsPanelIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds a new promo panel to the specified channel. Only authorized users (channel owners) can add promo panels.
     */
    async promopanelPromoPanelsPostRaw(requestParameters: PromopanelPromoPanelsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetChannelPromoPanelResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.title !== undefined) {
            formParams.append('Title', requestParameters.title as any);
        }

        if (requestParameters.description !== undefined) {
            formParams.append('Description', requestParameters.description as any);
        }

        if (requestParameters.image !== undefined) {
            formParams.append('Image', requestParameters.image as any);
        }

        if (requestParameters.imageLinkUrl !== undefined) {
            formParams.append('ImageLinkUrl', requestParameters.imageLinkUrl as any);
        }

        const response = await this.request({
            path: `/promopanel/promo-panels`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetChannelPromoPanelResponseFromJSON(jsonValue));
    }

    /**
     * Adds a new promo panel to the specified channel. Only authorized users (channel owners) can add promo panels.
     */
    async promopanelPromoPanelsPost(requestParameters: PromopanelPromoPanelsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetChannelPromoPanelResponse> {
        const response = await this.promopanelPromoPanelsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
