import { Gallery, Item } from 'react-photoswipe-gallery'
import { ValidatedFile } from '../../OnlyStream/utils/fileValidationUtils'
import { props } from '@stylexjs/stylex'
import { styles } from './CreatePostStyles'
import { Close } from '../../components/icons/Close'
import { Edit } from '../../components/icons/Edit'
import { memo } from 'react'
import { VideoPlayer } from '../../components/player/VideoPlayer'
import { Tooltip } from '../../components/Tooltip'
import useLanguage from '../../shared/stores/useLanguage'

type FilePreviewProps = {
  fileData: ValidatedFile[]
  removeFile: (id: string) => void
  onEditFile: (file: ValidatedFile) => void
}

export const FilePreview: React.FC<FilePreviewProps> = memo(
  ({ fileData, removeFile, onEditFile }) => {
    const { t } = useLanguage()

    return (
      <Gallery id="post-creation-gallery">
        {fileData.map(({ file, url, id, dimensions, progress, isUploaded }) => {
          const isImage = file.type.startsWith('image/')
          const isVideo = file.type.startsWith('video/')

          return (
            <div {...props(styles.previewItem)} key={id}>
              {isImage && (
                <>
                  <Item
                    original={url}
                    thumbnail={url}
                    id={id}
                    width={dimensions.width}
                    height={dimensions.height}
                  >
                    {({ ref, open }) => (
                      <img
                        {...props(styles.previewImage)}
                        ref={ref as unknown as React.Ref<HTMLImageElement>}
                        onClick={open}
                        src={url}
                        alt={`Preview of ${file.name}`}
                        draggable={false}
                      />
                    )}
                  </Item>
                  {progress === 100 && (
                    <button
                      {...props(styles.customizeImageEditor)}
                      onClick={() =>
                        onEditFile({
                          file,
                          url,
                          id,
                          dimensions,
                          isUploaded: false,
                        })
                      }
                    >
                      <Tooltip text={t('post.create.editImage')}>
                        <Edit width="1.3rem" height="1.3rem" />
                      </Tooltip>
                    </button>
                  )}
                </>
              )}

              {isVideo && (
                <VideoPlayer
                  options={{
                    sources: [{ src: url, type: file.type }],
                  }}
                  previewMode
                />
              )}

              {typeof progress === 'number' && (
                <div {...props(styles.progressBar)}>
                  <div
                    {...props(styles.progress)}
                    style={{ width: `${progress}%` }}
                  />
                </div>
              )}

              {isUploaded && (
                <button
                  {...props(styles.removeButton)}
                  onClick={() => removeFile(id)}
                >
                  <Tooltip text={t('post.create.deleteMedia')}>
                    <Close width="1.3rem" height="1.3rem" />
                  </Tooltip>
                </button>
              )}
            </div>
          )
        })}
      </Gallery>
    )
  }
)

FilePreview.displayName = 'FilePreview'
