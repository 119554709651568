/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetTransactionResponse } from './GetTransactionResponse';
import {
    GetTransactionResponseFromJSON,
    GetTransactionResponseFromJSONTyped,
    GetTransactionResponseToJSON,
} from './GetTransactionResponse';

/**
 * 
 * @export
 * @interface GetTransactionResponsePagedApiResponse
 */
export interface GetTransactionResponsePagedApiResponse {
    /**
     * 
     * @type {Array<GetTransactionResponse>}
     * @memberof GetTransactionResponsePagedApiResponse
     */
    data: Array<GetTransactionResponse>;
    /**
     * 
     * @type {number}
     * @memberof GetTransactionResponsePagedApiResponse
     */
    pageNumber?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetTransactionResponsePagedApiResponse
     */
    pageSize?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetTransactionResponsePagedApiResponse
     */
    totalItemCount: number;
    /**
     * 
     * @type {number}
     * @memberof GetTransactionResponsePagedApiResponse
     */
    totalPages: number;
}

/**
 * Check if a given object implements the GetTransactionResponsePagedApiResponse interface.
 */
export function instanceOfGetTransactionResponsePagedApiResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "totalItemCount" in value;
    isInstance = isInstance && "totalPages" in value;

    return isInstance;
}

export function GetTransactionResponsePagedApiResponseFromJSON(json: any): GetTransactionResponsePagedApiResponse {
    return GetTransactionResponsePagedApiResponseFromJSONTyped(json, false);
}

export function GetTransactionResponsePagedApiResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTransactionResponsePagedApiResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(GetTransactionResponseFromJSON)),
        'pageNumber': !exists(json, 'pageNumber') ? undefined : json['pageNumber'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'totalItemCount': json['totalItemCount'],
        'totalPages': json['totalPages'],
    };
}

export function GetTransactionResponsePagedApiResponseToJSON(value?: GetTransactionResponsePagedApiResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(GetTransactionResponseToJSON)),
        'pageNumber': value.pageNumber,
        'pageSize': value.pageSize,
        'totalItemCount': value.totalItemCount,
        'totalPages': value.totalPages,
    };
}

