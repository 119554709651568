/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  ErrorCode,
  SandBoxResponse,
} from '../models/index';
import {
    ApiErrorResponseFromJSON,
    ApiErrorResponseToJSON,
    ErrorCodeFromJSON,
    ErrorCodeToJSON,
    SandBoxResponseFromJSON,
    SandBoxResponseToJSON,
} from '../models/index';

export interface ErrorsandboxSpecificErrorGetRequest {
    errorCode?: ErrorCode;
}

/**
 * 
 */
export class ErrorSandboxApi extends runtime.BaseAPI {

    /**
     * Unprotected endpoint to return a specific error object with error code.   </br>  for 2xx: don\'t specify error code.   </br>  for 5xx: Casino.API.Contracts.Core.Responses.ErrorCode.TECHNICAL_ERROR.  </br>  for 4xx: any other error code   </br>
     */
    async errorsandboxSpecificErrorGetRaw(requestParameters: ErrorsandboxSpecificErrorGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SandBoxResponse>> {
        const queryParameters: any = {};

        if (requestParameters.errorCode !== undefined) {
            queryParameters['errorCode'] = requestParameters.errorCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/errorsandbox/specific-error`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SandBoxResponseFromJSON(jsonValue));
    }

    /**
     * Unprotected endpoint to return a specific error object with error code.   </br>  for 2xx: don\'t specify error code.   </br>  for 5xx: Casino.API.Contracts.Core.Responses.ErrorCode.TECHNICAL_ERROR.  </br>  for 4xx: any other error code   </br>
     */
    async errorsandboxSpecificErrorGet(requestParameters: ErrorsandboxSpecificErrorGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SandBoxResponse> {
        const response = await this.errorsandboxSpecificErrorGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
