import * as RadixTooltip from '@radix-ui/react-tooltip'
import { create, props } from '@stylexjs/stylex'
import { PropsWithChildren } from 'react'
import { fonts } from '../styles/font-styles'
import { color } from '../styles/token.stylex'

const styles = create({
  content: {
    borderRadius: '0.2rem',
    padding: '0.3rem 0.6rem',
    zIndex: 1000,
    color: color.codGray,
    backgroundColor: color.white,
    userSelect: 'none',
    borderBottom: `1px solid ${color.gold}`,
    animationDuration: '400ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    willChange: 'transform, opacity',
  },
  arrow: {
    fill: color.gold,
  },
})

type TooltipProps = {
  text: string | null
  delayMs?: number
}

export const Tooltip: React.FC<PropsWithChildren<TooltipProps>> = ({
  children,
  text,
  delayMs = 100,
}) => (
  <RadixTooltip.Root delayDuration={delayMs}>
    <RadixTooltip.Trigger asChild>
      <div>{children}</div>
    </RadixTooltip.Trigger>
    <RadixTooltip.Portal>
      <RadixTooltip.Content
        {...props(styles.content, fonts.extraSmallRegular)}
        sideOffset={4}
        side="top"
      >
        {text}
        <RadixTooltip.Arrow {...props(styles.arrow)} />
      </RadixTooltip.Content>
    </RadixTooltip.Portal>
  </RadixTooltip.Root>
)
