import React, { useEffect, useState } from 'react'
import { Swiper } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Navigation, Pagination } from 'swiper/modules'
import { Gallery } from 'react-photoswipe-gallery'
import 'photoswipe/dist/photoswipe.css'
import { props } from '@stylexjs/stylex'
import { ImageSlide } from './ImageSlide'
import { VideoSlide } from './VideoSlide'
import { PhotoSwipeOptions, styles, swiperStyleProps } from './slidesStyles'
import { fetchImageDimensions } from '../utils'
import { isMobile } from 'react-device-detect'

type MediaSwiperProps = {
  images?: { source: string }[]
  videos?: { url: string; thumbnailUrl: string }[]
}

export const Slides: React.FC<MediaSwiperProps> = ({
  images = [],
  videos = [],
}) => {
  const [imageDims, setImageDims] = useState<
    { source: string; width: number; height: number }[]
  >([])

  useEffect(() => {
    if (!images.length) return
    fetchImageDimensions(images).then(setImageDims).catch(console.error)
  }, [images])

  if (!images.length && !videos.length) return null

  return (
    <Gallery options={PhotoSwipeOptions}>
      <Swiper
        modules={[Navigation, Pagination]}
        navigation
        pagination={{ clickable: true }}
        {...props(styles.swiperContainer)}
        style={swiperStyleProps}
        cssMode={isMobile}
        noSwipingClass="video-js"
      >
        {imageDims.map((img, i) => (
          <ImageSlide
            key={i}
            source={img.source}
            width={img.width}
            height={img.height}
          />
        ))}
        {videos.map((video, i) => (
          <VideoSlide
            key={i}
            url={video.url}
            thumbnailUrl={video.thumbnailUrl}
          />
        ))}
      </Swiper>
    </Gallery>
  )
}
