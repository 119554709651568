import { Chart } from '../../components/icons/Chart'
import { Coin } from '../../components/icons/Coin'
import { Currency } from '../../components/icons/Currency'
import { Edit } from '../../components/icons/Edit'
import { HelpAndSupport } from '../../components/icons/HelpAndSupport'
import { House } from '../../components/icons/House'
import { Logout } from '../../components/icons/Logout'
import { Star } from '../../components/icons/Star'
import { User } from '../../components/icons/User'
import { Wallet } from '../../components/icons/Wallet'
import { routes } from '../../router/routes'
import { HELPDESK_URL } from '../../constants/sites'
import { MenuItem } from './types'
import { FullCreator } from '../../components/types'
import { GetUserInfoResponse } from '../../client'
import { Tag } from '../../components/icons/Tag'
import { Receipt } from '../../components/icons/Receipt'

const creatorSpecificItems: MenuItem[] = [
  {
    icon: Tag,
    labelKey: 'userMenu.subscriptionPrice',
    route: routes.profileRoutes.subscriptionPrice,
  },
  {
    icon: Wallet,
    labelKey: 'userMenu.bank',
    route: routes.bank,
    clickUpId: '86c1ag087',
  },
  {
    icon: Coin,
    labelKey: 'userMenu.earnings',
    route: routes.profileRoutes.earnings,
  },
  {
    icon: Currency,
    labelKey: 'userMenu.payoutRequest',
    route: routes.payoutRequest,
    clickUpId: '86c0yv1xy',
  },
  {
    icon: Star,
    labelKey: 'userMenu.streamKey',
    route: routes.profileRoutes.streamKey,
    hasSeparator: true,
  },
]

const loggedInFooterItems: MenuItem[] = [
  {
    icon: Logout,
    labelKey: 'userMenu.logout',
    route: routes.logout,
  },
]

const nonCreatorItem: MenuItem = {
  icon: Star,
  labelKey: 'userMenu.becomeACreator',
  route: routes.becomeACreator,
  hasSeparator: true,
}

const basicLoggedInItems: MenuItem[] = [
  {
    icon: Chart,
    labelKey: 'userMenu.payments',
    route: routes.payments,
    clickUpId: '86c1afytn',
  },
  {
    icon: Edit,
    labelKey: 'userMenu.editProfile',
    route: routes.profileRoutes.myProfile,
    hasSeparator: true,
  },
]

const basicLoggedOutItems: MenuItem[] = [
  {
    icon: House,
    labelKey: 'userMenu.home',
    route: routes.home,
  },
]

const getHelpdeskItem = (languageCode: string): MenuItem => ({
  icon: HelpAndSupport,
  labelKey: 'userMenu.helpAndSupport',
  route: `${HELPDESK_URL}/${languageCode}`,
  isExternal: true,
})

const getProfileItem = (profileUrl: string): MenuItem => ({
  icon: User,
  labelKey: 'userMenu.myProfile',
  route: profileUrl,
})

const paymentHistoryItem: MenuItem = {
  icon: Receipt,
  labelKey: 'userMenu.paymentHistory',
  route: routes.profileRoutes.paymentHistory,
}

const getLoggedInItems = (
  isCreator: boolean,
  profileUrl: string,
  languageCode: string
) => {
  return [
    getProfileItem(profileUrl),
    ...basicLoggedInItems,
    ...(isCreator ? creatorSpecificItems : [nonCreatorItem]),
    paymentHistoryItem,
    getHelpdeskItem(languageCode),
  ]
}

const getLoggedOutItems = (languageCode: string) => {
  const loggedOutItems: MenuItem[] = [
    ...basicLoggedOutItems,
    getHelpdeskItem(languageCode),
  ]

  return loggedOutItems
}

export const getMenuData = (
  data: GetUserInfoResponse | undefined,
  languageCode: string
): {
  menuItems: MenuItem[]
  footerItems?: MenuItem[]
  profile?: FullCreator
} => {
  if (!data) return { menuItems: getLoggedOutItems(languageCode) }

  const profileUrl = `/${data.streamerChannel.slug}`

  const profile: FullCreator = {
    displayName: data.displayName,
    profilePictureUrl: data.profilePicture?.source,
    fullName: data.displayName,
    isVerified: data.streamerChannel.isVerified,
    profileUrl,
  }

  return {
    menuItems: getLoggedInItems(
      data.isContentCreator,
      profileUrl,
      languageCode
    ),
    footerItems: loggedInFooterItems,
    profile,
  }
}
