import { create } from '@stylexjs/stylex'
import { color, spacing } from '../../styles/token.stylex'

export const styles = create({
  header: {
    padding: spacing.normal,
  },
  content: {
    paddingBottom: 0,
  },
  dropzoneArea: {
    overflowX: 'visible',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '8px',
    paddingBottom: 0,
    '::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
  },

  dropzoneArea_dragActive: {
    border: `1px solid ${color.gold}`,
  },

  previewWrapper: {
    display: 'flex',
    gap: spacing.small,
    flexWrap: 'nowrap',
    overflowX: 'scroll',
    marginBottom: spacing.small,
    paddingBottom: spacing.small,

    '::-webkit-scrollbar': { height: '8px' },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '4px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#555',
    },
  },

  previewWrapper_hidden: {
    display: 'none',
  },

  previewItem: {
    position: 'relative',
    width: '130px',
    height: '130px',
    flex: '0 0 130px',
    borderRadius: '8px',
    overflow: 'hidden',
    backgroundColor: color.mineShaft,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  previewImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    cursor: 'pointer',
  },

  removeButton: {
    position: 'absolute',
    top: 8,
    right: 8,
    backgroundColor: {
      default: color.codGrayLight,
      ':hover': color.codGray,
    },
    color: {
      default: color.white,
      ':hover': color.gold,
    },
    border: 'none',
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    zIndex: {
      default: 1,
      ':hover': 2,
    },
  },

  addButton: {
    display: 'flex',
    flex: '0 0 60px',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all .15s',
    backgroundColor: {
      default: color.mineShaft,
      ':hover': color.mineShaftDark,
    },
    borderRadius: '8px',
    cursor: 'pointer',
    color: {
      default: '#888',
      ':hover': color.gold,
    },
  },

  addButton_hidden: {
    display: 'none',
  },

  actionButtonsWrapper: {
    borderTop: `1px solid ${color.dustyGray}`,
    padding: spacing.normal,
  },

  mediaButton: {
    display: 'flex',
    width: 'min-content',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative',
    zIndex: 99,
    color: {
      default: color.white,
      ':hover': color.gold,
    },
    transition: 'all .15s',
    opacity: {
      ':hover': {
        '::after': 0.3,
      },
    },
    '::after': {
      content: '""',
      position: 'absolute',
      left: '-9px',
      top: -9,
      width: '36px',
      height: '36px',
      transition: 'opacity .15s',
      pointerEvents: 'none',
      opacity: 0,
      borderRadius: '1000px',
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      display: 'block',
    },
  },

  hiddenInput: {
    display: 'none',
  },

  textAreaWrapper: {
    ':not(#__id) fieldset': {
      border: 'none !important',
    },
    ':not(#__id) textarea': {
      padding: '10px 16px',
    },
  },

  progressBar: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '5px',
    backgroundColor: '#e0e0e0',
    borderRadius: '0 0 4px 4px',
  },

  progress: {
    height: '100%',
    backgroundColor: color.gold,
    borderRadius: '0 0 4px 4px',
  },

  customizeImageEditor: {
    position: 'absolute',
    bottom: 8,
    right: 8,
    backgroundColor: {
      default: 'rgba(0, 0, 0, 0.5)',
    },
    transform: {
      default: 'scale(1)',
      ':hover': 'scale(1.05)',
    },
    zIndex: {
      default: 1,
      ':hover': 2,
    },
    color: {
      default: color.white,
      ':hover': color.gold,
    },
    border: 'none',
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '3px',
  },
})
