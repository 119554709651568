export const formatLikesCount = (likesCount: number): string =>
  likesCount >= 1000 ? `${(likesCount / 1000).toFixed()}k` : `${likesCount}`

export async function fetchImageDimensions(images: { source: string }[]) {
  return Promise.all(
    images.map((i) => {
      return new Promise<{ source: string; width: number; height: number }>(
        (resolve, reject) => {
          const img = new Image()
          img.src = i.source
          img.onload = () =>
            resolve({
              source: i.source,
              width: img.naturalWidth,
              height: img.naturalHeight,
            })
          img.onerror = () =>
            reject(new Error(`Failed to load image: ${i.source}`))
        }
      )
    })
  )
}
