import LinkifyIt from 'linkify-it'
import tldsList from 'tlds'
import { TextLink } from '../../../components/TextLink'
import React from 'react'

const SITE_BASE_URL = import.meta.env.VITE_SITE_BASE_URL

const linkify = new LinkifyIt()
  .tlds(tldsList)
  .tlds('onion', true)
  .add('git:', 'http:')
  .add('ftp:', null)
  .set({
    fuzzyLink: true,
    fuzzyEmail: true,
    fuzzyIP: true,
  })

const mentionRegex = /@\[(.+?)\]\((.+?)\)/g

const parseMentions = (text: string): React.ReactNode[] => {
  const elements: React.ReactNode[] = []
  let lastIndex = 0
  let match

  while ((match = mentionRegex.exec(text))) {
    const [, displayName, userId] = match

    // Add text before the mention
    if (match.index > lastIndex) {
      elements.push(text.substring(lastIndex, match.index))
    }

    elements.push(
      <TextLink
        key={`mention-${userId}`}
        to={`/${displayName}`}
        font="smallRegular"
      >
        {`@${displayName}`}
      </TextLink>
    )

    lastIndex = mentionRegex.lastIndex
  }

  // Add remaining text after the last mention
  if (lastIndex < text.length) {
    elements.push(text.substring(lastIndex))
  }

  return elements
}

const isInternalLink = (url: string): boolean => {
  try {
    const linkUrl = new URL(url)
    const siteUrl = new URL(SITE_BASE_URL)
    return linkUrl.hostname === siteUrl.hostname
  } catch {
    return false
  }
}

const linkifyTextElements = (
  elements: React.ReactNode[],
  allowExternalLinks: boolean
): React.ReactNode[] =>
  elements.map((element) => {
    if (typeof element !== 'string') return element

    const matches = linkify.match(element)
    if (!matches) return element

    const linkifiedElements: React.ReactNode[] = []
    let lastLinkIndex = 0

    matches.forEach((linkMatch, index) => {
      if (linkMatch.index > lastLinkIndex) {
        linkifiedElements.push(
          element.substring(lastLinkIndex, linkMatch.index)
        )
      }

      // Only add links within the site base URL
      if (isInternalLink(linkMatch.url)) {
        linkifiedElements.push(
          <TextLink key={index} to={linkMatch.url} font="smallRegular">
            {linkMatch.text}
          </TextLink>
        )
      } else {
        linkifiedElements.push(
          allowExternalLinks ? (
            <TextLink
              key={index}
              to={linkMatch.url}
              font="smallRegular"
              isExternal
            >
              {linkMatch.text}
            </TextLink>
          ) : (
            linkMatch.text
          )
        )
      }

      lastLinkIndex = linkMatch.lastIndex
    })

    // Add remaining text after the last link match
    if (lastLinkIndex < element.length) {
      linkifiedElements.push(element.substring(lastLinkIndex))
    }

    return linkifiedElements
  })

const addLineBreaks = (elements: React.ReactNode[]): React.ReactNode[] => {
  const result: React.ReactNode[] = []

  elements.forEach((element, index) => {
    if (typeof element === 'string') {
      const parts = element.split('\n')
      parts.forEach((part, i) => {
        if (i > 0) {
          result.push(<br key={`br-${index}-${i}`} />)
        }
        if (part) {
          result.push(part)
        }
      })
    } else if (Array.isArray(element)) {
      result.push(...addLineBreaks(element))
    } else {
      result.push(element)
    }
  })

  return result
}

export const replaceLinksWithAnchors = (
  text: string,
  key?: string,
  allowExternalLinks: boolean = false
): React.ReactNode => {
  const mentionElements = parseMentions(text)
  const linkifiedText = linkifyTextElements(mentionElements, allowExternalLinks)
  const textWithLineBreaks = addLineBreaks(linkifiedText)

  return <React.Fragment key={key}>{textWithLineBreaks}</React.Fragment>
}
