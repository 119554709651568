/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PaymentType = {
    Tip: 'tip',
    Subscription: 'subscription',
    Ppv: 'ppv'
} as const;
export type PaymentType = typeof PaymentType[keyof typeof PaymentType];


export function PaymentTypeFromJSON(json: any): PaymentType {
    return PaymentTypeFromJSONTyped(json, false);
}

export function PaymentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentType {
    return json as PaymentType;
}

export function PaymentTypeToJSON(value?: PaymentType | null): any {
    return value as any;
}

