import React from 'react'
import { create, props } from '@stylexjs/stylex'
import { CreatePost } from './create/CreatePost'
import { spacing } from '../styles/token.stylex'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  container: {
    display: 'flex',
    maxWidth: '888px',
    margin: '0 auto',
    padding: {
      default: spacing.normal,
      [largeScreen]: spacing.extraLarger,
    },
    width: '100%',
    ':not(_id) > div': {
      width: '100%',
    },
  },
})

const CreatePostPage: React.FC = () => (
  <div {...props(styles.container)}>
    <CreatePost />
  </div>
)

export default CreatePostPage
