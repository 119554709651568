import type { SVGProps } from 'react'

export const Heart: React.FC<
  SVGProps<SVGElement> & { variant?: 'filled' | 'outline' }
> = ({
  stroke = 'currentColor',
  height = '1.5rem',
  width = '1.5rem',
  variant = 'outline',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill={variant === 'filled' ? stroke : 'none'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99425 4.27985C8.32813 2.332 5.54975 1.80804 3.46221 3.59168C1.37466 5.37532 1.08077 8.35748 2.72012 10.467C4.08314 12.2209 8.2081 15.9201 9.56004 17.1174C9.7113 17.2513 9.78692 17.3183 9.87514 17.3446C9.95213 17.3676 10.0364 17.3676 10.1134 17.3446C10.2016 17.3183 10.2772 17.2513 10.4285 17.1174C11.7804 15.9201 15.9054 12.2209 17.2684 10.467C18.9077 8.35748 18.6497 5.35656 16.5263 3.59168C14.4029 1.8268 11.6604 2.332 9.99425 4.27985Z"
      stroke={variant === 'outline' ? stroke : undefined}
      strokeWidth={variant === 'outline' ? '1.5' : undefined}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
