import { create, props } from '@stylexjs/stylex'
import { Comments } from '../components/icons/Comments'
import { IconButton } from '../components/IconButton'
import { Button } from '../components/Button'
import useLanguage from '../shared/stores/useLanguage'
import { Currency } from '../components/icons/Currency'
import { color, spacing } from '../styles/token.stylex'
import { fonts } from '../styles/font-styles'
import { formatLikesCount } from './utils'
import { LikeIcon } from './LikeIcon'

const styles = create({
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: spacing.normal,
  },
  leftIcons: {
    display: 'flex',
    gap: spacing.small,
  },
  like: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.extraSmall,
  },
  count: {
    color: color.postCardLikeCountTextColor,
  },
})

type PostActionsProps = {
  onComment?: () => void
  onTip?: () => void
  onLike?: () => void
  likesCount: number
  isLiked: boolean
  likeInProgress: boolean
}

export const PostActions: React.FC<PostActionsProps> = ({
  onLike,
  onComment,
  onTip,
  likesCount,
  isLiked,
  likeInProgress,
}) => {
  const { t } = useLanguage()

  const likeIcon = () => <LikeIcon isLiked={isLiked} />

  return (
    <div {...props(styles.actions)}>
      <div {...props(styles.leftIcons)}>
        <div {...props(styles.like)}>
          <IconButton
            onClick={onLike}
            disabled={!onLike || likeInProgress}
            icon={likeIcon}
            tooltip={
              isLiked ? t('feeds.channel.unlike') : t('feeds.channel.like')
            }
            variant="small"
          />
          <span {...props(styles.count, fonts.smallRegular)}>
            {formatLikesCount(likesCount)}
          </span>
        </div>
        {onComment && (
          <IconButton
            onClick={onComment}
            disabled={!onComment}
            icon={Comments}
            variant="small"
          />
        )}
      </div>
      {onTip && (
        <Button onClick={onTip} disabled={!onTip} variant="transparent">
          {t('post.postCard.postCardActions.sendTipButtonText')}{' '}
          <Currency stroke={color.gold} />
        </Button>
      )}
    </div>
  )
}
