import { PropsWithChildren } from 'react'
import { create, props } from '@stylexjs/stylex'
import { fonts } from '../../styles/font-styles'
import { Button } from '../../components/Button'
import { color, spacing } from '../../styles/token.stylex'

type ActionButton = {
  label: string
  disabled?: boolean
  onClick?: () => void
}

type PanelProps = {
  title?: string
  //note: consider removing this after post upload page refactor
  actionButtons?: {
    primary: ActionButton
    secondary?: ActionButton
  }
  variant?: 'createPost'
}

const styles = create({
  container: {
    marginBottom: '20px',
    backgroundColor: color.codGrayLight,
    borderRadius: '0.5rem',
  },
  content: {
    borderTop: `solid 1px ${color.mineShaft}`,
    padding: spacing.normal,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${spacing.extraSmall} ${spacing.normal}`,
    textTransform: 'capitalize',
    color: color.white,
  },
  actionButtons: {
    display: 'flex',
    gap: '0.75rem',
  },
})

const createPostStyles = create({
  header: {
    padding: spacing.normal,
  },
  content: {
    paddingBottom: 0,
  },
})

export const DashboardPanel: React.FC<PropsWithChildren<PanelProps>> = ({
  title,
  children,
  actionButtons,
  variant,
}) => (
  <div {...props(styles.container)}>
    {title && (
      <div
        {...props(
          styles.header,
          fonts.normalSemiBold,
          variant === 'createPost' && createPostStyles.header
        )}
      >
        <p>{title}</p>
        {actionButtons && (
          <div {...props(styles.actionButtons)}>
            {actionButtons.secondary && (
              <Button
                onClick={actionButtons.secondary.onClick}
                disabled={actionButtons.secondary.disabled}
                type="button"
              >
                {actionButtons.secondary.label}
              </Button>
            )}
            <Button
              onClick={actionButtons.primary.onClick}
              disabled={actionButtons.primary.disabled}
              type="button"
              variant="cta"
            >
              {actionButtons.primary.label}
            </Button>
          </div>
        )}
      </div>
    )}
    <div
      {...props(
        styles.content,
        variant === 'createPost' && createPostStyles.content
      )}
    >
      {children}
    </div>
  </div>
)
