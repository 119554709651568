import React, { memo } from 'react'
import { SwiperSlide } from 'swiper/react'
import { props } from '@stylexjs/stylex'
import { styles } from './slidesStyles'
import {
  IVideoPlayerProps,
  VideoPlayer,
} from '../../components/player/VideoPlayer'

type VideoSlideProps = {
  url: string
  thumbnailUrl: string
}

export const VideoSlide: React.FC<VideoSlideProps> = memo(
  ({ url, thumbnailUrl }) => {
    const videoJsOptions: IVideoPlayerProps = {
      options: {
        sources: [{ src: url }],
        poster: thumbnailUrl,
      },
    }
    return (
      <SwiperSlide {...props(styles.slideContainer)}>
        <div {...props(styles.videoContainer)}>
          <VideoPlayer options={videoJsOptions.options} />
        </div>
      </SwiperSlide>
    )
  }
)

VideoSlide.displayName = 'SwiperSlide'
