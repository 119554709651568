import { ValidatedFile } from '../../OnlyStream/utils/fileValidationUtils'

export const filterValidAssetIds = (files: ValidatedFile[]) =>
  files
    .filter((file) => file.isUploaded && file.assetId)
    .map((file) => file.assetId!)

export const isPostValid = (postContent: string, files: ValidatedFile[]) =>
  postContent.trim() !== '' || files.length > 0

export const processCroppedImage = async (
  croppedImageUrl: string,
  originalFile: ValidatedFile,
  updateFileDataById: (id: string, updatedFile: ValidatedFile) => void,
  uploadFile: (file: ValidatedFile) => void
): Promise<void> => {
  try {
    const response = await fetch(croppedImageUrl)
    const blob = await response.blob()

    const croppedFile = new File([blob], originalFile.file.name, {
      type: originalFile.file.type,
    })

    const croppedImage = new Image()
    croppedImage.src = croppedImageUrl

    croppedImage.onload = () => {
      const { width, height } = croppedImage

      const updatedFile: ValidatedFile = {
        ...originalFile,
        file: croppedFile,
        url: croppedImageUrl,
        dimensions: { width, height },
        isUploaded: false,
        assetId: undefined,
      }

      updateFileDataById(originalFile.id, updatedFile)
      uploadFile(updatedFile)
    }
  } catch (error) {
    console.error('Failed to process cropped image:', error)
  }
}
