import { Middleware } from '../client'

export const createTokenMiddleware = (
  getToken: () => Promise<string | undefined>
): Middleware => ({
  pre: async (context) => {
    const token = await getToken()

    if (!token) return

    context.init.headers = {
      ...context.init.headers,
      Authorization: `Bearer ${token}`,
    }
  },
})
