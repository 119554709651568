import { ModelError } from '../client'

const redirectToLoginErrorCodes = [
  'login_required',
  'missing_refresh_token',
  'invalid_grant',
]

const isAuthError = (error: unknown): error is { error: string } =>
  typeof error === 'object' && error !== null && 'error' in error

export const shouldRedirectToLogin = (error: unknown): boolean =>
  isAuthError(error) && redirectToLoginErrorCodes.includes(error.error)

export const isModelErrorResponse = (error: unknown): error is ModelError =>
  typeof error === 'object'
