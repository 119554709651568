import { create, keyframes, props } from '@stylexjs/stylex'
import { SVGProps } from 'react'
import { ButtonLoader } from '../icons/ButtonLoader'

const spin = keyframes({
  from: { transform: 'rotate(0deg)' },
  to: { transform: 'rotate(360deg)' },
})

const styles = create({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    animation: `${spin} 1s linear infinite`,
  },
})

export const LoadingIndicator: React.FC<SVGProps<SVGElement>> = ({
  height = '1.5rem',
  width = '1.5rem',
  stroke = 'currentColor',
}) => (
  <div {...props(styles.container)}>
    <ButtonLoader width={width} height={height} stroke={stroke} />
  </div>
)
