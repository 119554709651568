import { useAuth0 } from '@auth0/auth0-react'
import { PropsWithChildren, useEffect } from 'react'
import { useApi } from '../api/ApiContext'
import { useQuery, useQueryClient } from 'react-query'
import { UserContext } from './user-context'
import CompleteRegistration from './email-verification/CompleteRegistration'
import { LoadingScreen } from '../components/indicators/LoadingScreen'
import { queryKeys } from '../constants/query-keys'

export const UserContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const auth = useAuth0()
  const { userApi } = useApi()
  const queryClient = useQueryClient()

  const { data, isLoading, error } = useQuery({
    queryKey: queryKeys.user.default,
    queryFn: () => userApi.userGet(),
    enabled: auth.isAuthenticated,
  })

  useEffect(() => {
    queryClient.invalidateQueries(queryKeys.user.default)
    queryClient.removeQueries(queryKeys.user.default)
  }, [auth.isAuthenticated, queryClient])

  const reloadUser = () => queryClient.invalidateQueries(queryKeys.user.default)

  const isUserLoading = auth.isLoading || (auth.isAuthenticated && isLoading)
  const isVerificationNeeded = auth.user && !auth.user.email_verified

  return (
    <UserContext.Provider
      value={{
        isLoading: auth.isLoading || isLoading,
        isError: !!(auth.error || error),
        data: data ?? undefined,
        reloadUser,
      }}
    >
      {isUserLoading && <LoadingScreen />}
      {!isUserLoading && isVerificationNeeded && <CompleteRegistration />}
      {!isUserLoading && !isVerificationNeeded && children}
    </UserContext.Provider>
  )
}
