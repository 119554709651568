/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetTransactionUserResponse } from './GetTransactionUserResponse';
import {
    GetTransactionUserResponseFromJSON,
    GetTransactionUserResponseFromJSONTyped,
    GetTransactionUserResponseToJSON,
} from './GetTransactionUserResponse';
import type { PaymentType } from './PaymentType';
import {
    PaymentTypeFromJSON,
    PaymentTypeFromJSONTyped,
    PaymentTypeToJSON,
} from './PaymentType';

/**
 * 
 * @export
 * @interface GetTransactionResponse
 */
export interface GetTransactionResponse {
    /**
     * The amount of the transaction.
     * @type {number}
     * @memberof GetTransactionResponse
     */
    amount: number;
    /**
     * the amount of VAT added to Casino.API.Contracts.V1.Responses.Payout.GetTransactionResponse.Amount"/>
     * @type {number}
     * @memberof GetTransactionResponse
     */
    vatAmount: number;
    /**
     * The net amount of the transaction.
     * @type {number}
     * @memberof GetTransactionResponse
     */
    net: number;
    /**
     * The fee of the transaction kept by the platform. Example fee: 20% of the amount.
     * @type {number}
     * @memberof GetTransactionResponse
     */
    fee: number;
    /**
     * the date and time the transaction was created.
     * @type {Date}
     * @memberof GetTransactionResponse
     */
    createdAt: Date;
    /**
     * The currency of the transaction.
     * @type {string}
     * @memberof GetTransactionResponse
     */
    currency: string;
    /**
     * 
     * @type {GetTransactionUserResponse}
     * @memberof GetTransactionResponse
     */
    user: GetTransactionUserResponse;
    /**
     * the id of the transaction.
     * @type {number}
     * @memberof GetTransactionResponse
     */
    id: number;
    /**
     * 
     * @type {PaymentType}
     * @memberof GetTransactionResponse
     */
    paymentType: PaymentType;
}

/**
 * Check if a given object implements the GetTransactionResponse interface.
 */
export function instanceOfGetTransactionResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "vatAmount" in value;
    isInstance = isInstance && "net" in value;
    isInstance = isInstance && "fee" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "paymentType" in value;

    return isInstance;
}

export function GetTransactionResponseFromJSON(json: any): GetTransactionResponse {
    return GetTransactionResponseFromJSONTyped(json, false);
}

export function GetTransactionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTransactionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'vatAmount': json['vatAmount'],
        'net': json['net'],
        'fee': json['fee'],
        'createdAt': (new Date(json['createdAt'])),
        'currency': json['currency'],
        'user': GetTransactionUserResponseFromJSON(json['user']),
        'id': json['id'],
        'paymentType': PaymentTypeFromJSON(json['paymentType']),
    };
}

export function GetTransactionResponseToJSON(value?: GetTransactionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'vatAmount': value.vatAmount,
        'net': value.net,
        'fee': value.fee,
        'createdAt': (value.createdAt.toISOString()),
        'currency': value.currency,
        'user': GetTransactionUserResponseToJSON(value.user),
        'id': value.id,
        'paymentType': PaymentTypeToJSON(value.paymentType),
    };
}

