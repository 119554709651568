/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DepositMethod } from './DepositMethod';
import {
    DepositMethodFromJSON,
    DepositMethodFromJSONTyped,
    DepositMethodToJSON,
} from './DepositMethod';

/**
 * Represents a payment method with VAT calculation.
 * @export
 * @interface PaymentMethodResponse
 */
export interface PaymentMethodResponse {
    /**
     * 
     * @type {DepositMethod}
     * @memberof PaymentMethodResponse
     */
    method: DepositMethod;
    /**
     * The VAT price for the payment method.
     * @type {number}
     * @memberof PaymentMethodResponse
     */
    vatPrice: number;
}

/**
 * Check if a given object implements the PaymentMethodResponse interface.
 */
export function instanceOfPaymentMethodResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "method" in value;
    isInstance = isInstance && "vatPrice" in value;

    return isInstance;
}

export function PaymentMethodResponseFromJSON(json: any): PaymentMethodResponse {
    return PaymentMethodResponseFromJSONTyped(json, false);
}

export function PaymentMethodResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentMethodResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'method': DepositMethodFromJSON(json['method']),
        'vatPrice': json['vatPrice'],
    };
}

export function PaymentMethodResponseToJSON(value?: PaymentMethodResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'method': DepositMethodToJSON(value.method),
        'vatPrice': value.vatPrice,
    };
}

