import React from 'react'
import { LoadingScreen } from '../components/indicators/LoadingScreen'
import { useUserContext } from './user-context'
import { Navigate } from 'react-router-dom'

const CallbackPage: React.FC = () => {
  const { isLoading } = useUserContext()

  if (isLoading) return <LoadingScreen />

  return <Navigate to="/" />
}

export default CallbackPage
