/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DepositRequest,
  DepositResponse,
  GetDepositHistoryResponsePagedApiResponse,
  GetPaymentMethodsVatResponse,
  PaymentType,
} from '../models/index';
import {
    DepositRequestFromJSON,
    DepositRequestToJSON,
    DepositResponseFromJSON,
    DepositResponseToJSON,
    GetDepositHistoryResponsePagedApiResponseFromJSON,
    GetDepositHistoryResponsePagedApiResponseToJSON,
    GetPaymentMethodsVatResponseFromJSON,
    GetPaymentMethodsVatResponseToJSON,
    PaymentTypeFromJSON,
    PaymentTypeToJSON,
} from '../models/index';

export interface PaymentsCallbackQaicashDepositStatusPostRequest {
    body?: any | null;
}

export interface PaymentsMethodsVatGetRequest {
    type?: PaymentType;
    price?: number;
}

export interface PaymentsPayPostRequest {
    depositRequest?: DepositRequest;
}

export interface PaymentsTransactionsGetRequest {
    pageNumber?: number;
    pageSize?: number;
}

/**
 * 
 */
export class PaymentsApi extends runtime.BaseAPI {

    /**
     * Endpoint for server-to-server payment callback from QaiCash.
     */
    async paymentsCallbackQaicashDepositStatusPostRaw(requestParameters: PaymentsCallbackQaicashDepositStatusPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/payments/callback/qaicash/deposit-status`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Endpoint for server-to-server payment callback from QaiCash.
     */
    async paymentsCallbackQaicashDepositStatusPost(requestParameters: PaymentsCallbackQaicashDepositStatusPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.paymentsCallbackQaicashDepositStatusPostRaw(requestParameters, initOverrides);
    }

    /**
     * Retrieves available payment methods and calculates VAT for the given price.
     */
    async paymentsMethodsVatGetRaw(requestParameters: PaymentsMethodsVatGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPaymentMethodsVatResponse>> {
        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.price !== undefined) {
            queryParameters['price'] = requestParameters.price;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/payments/methods-vat`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPaymentMethodsVatResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves available payment methods and calculates VAT for the given price.
     */
    async paymentsMethodsVatGet(requestParameters: PaymentsMethodsVatGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPaymentMethodsVatResponse> {
        const response = await this.paymentsMethodsVatGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Initiates a payment request.
     */
    async paymentsPayPostRaw(requestParameters: PaymentsPayPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DepositResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/payments/pay`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DepositRequestToJSON(requestParameters.depositRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DepositResponseFromJSON(jsonValue));
    }

    /**
     * Initiates a payment request.
     */
    async paymentsPayPost(requestParameters: PaymentsPayPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DepositResponse> {
        const response = await this.paymentsPayPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async paymentsTransactionsGetRaw(requestParameters: PaymentsTransactionsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDepositHistoryResponsePagedApiResponse>> {
        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['PageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/payments/transactions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDepositHistoryResponsePagedApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async paymentsTransactionsGet(requestParameters: PaymentsTransactionsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDepositHistoryResponsePagedApiResponse> {
        const response = await this.paymentsTransactionsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
