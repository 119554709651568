import type { SVGProps } from 'react'

export const PlusWithoutCircle: React.FC<SVGProps<SVGElement>> = ({
  height = '2.5rem',
  width = '2.5rem',
  fill = 'currentColor',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 41 40"
    fill="none"
  >
    <rect x="11.5" y="19.1" width="18" height="1.8" fill={fill} />
    <rect
      x="21.4"
      y="11"
      width="18"
      height="1.8"
      transform="rotate(90 21.4 11)"
      fill={fill}
    />
  </svg>
)
