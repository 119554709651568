import { forwardRef } from 'react'
import { Mention, MentionsInput } from 'react-mentions'
import { InputPanel } from './InputPanel'
import { props } from '@stylexjs/stylex'
import { mentionsStyles } from './styles'

type MentionTextAreaProps = {
  id: string
  value: string
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  mentionData: { id: string; display: string }[]
  placeholder?: string
  error?: string
  maxLength?: number
  required?: boolean
  instruction?: string
  label?: string
}

export const MentionsTextArea = forwardRef<
  HTMLTextAreaElement,
  MentionTextAreaProps
>(
  (
    {
      id,
      value,
      onChange,
      mentionData,
      placeholder,
      error,
      maxLength,
      required,
      instruction,
      label,
    },
    ref
  ) => (
    <InputPanel
      id={id}
      label={label}
      error={error}
      maxLength={maxLength}
      required={required}
      instruction={instruction}
      characterCount={value.length}
    >
      <MentionsInput
        value={value}
        onChange={onChange as (e: unknown) => void}
        placeholder={placeholder}
        maxLength={maxLength}
        spellCheck={false}
        inputRef={ref as React.Ref<HTMLTextAreaElement>}
        {...props(mentionsStyles.input)}
      >
        <Mention
          trigger="@"
          data={mentionData}
          markup="@[__display__](__id__)"
          displayTransform={(id, display) => `@${display}`}
        />
      </MentionsInput>
    </InputPanel>
  )
)

MentionsTextArea.displayName = 'MentionsTextArea'
