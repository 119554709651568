/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DepositStatus = {
    Created: 'created',
    Processing: 'processing',
    Pending: 'pending',
    Claimed: 'claimed',
    Success: 'success',
    Failed: 'failed'
} as const;
export type DepositStatus = typeof DepositStatus[keyof typeof DepositStatus];


export function DepositStatusFromJSON(json: any): DepositStatus {
    return DepositStatusFromJSONTyped(json, false);
}

export function DepositStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): DepositStatus {
    return json as DepositStatus;
}

export function DepositStatusToJSON(value?: DepositStatus | null): any {
    return value as any;
}

