import { PropsWithChildren, useMemo } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { useErrorNotification } from './translations/useErrorNotification'

export const QueryClientWithDefaultErrorHandlingProvider: React.FC<
  PropsWithChildren
> = ({ children }) => {
  const notifyError = useErrorNotification()

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            onError: notifyError(() => {}),
          },
          mutations: {
            onError: notifyError(() => {}),
          },
        },
      }),
    [notifyError]
  )

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  )
}
