import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import 'react-toastify/dist/ReactToastify.css'
import './index.css'
import { initializeTracking } from './initializeTracking'

const BUILD_VERSION = `${import.meta.env.VITE_BUILD_VERSION}` //coming from the .env.local file. CI overwrites that.
console.log('Build version: ', BUILD_VERSION)

initializeTracking()

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
