/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ReportReason } from './ReportReason';
import {
    ReportReasonFromJSON,
    ReportReasonFromJSONTyped,
    ReportReasonToJSON,
} from './ReportReason';
import type { ReportType } from './ReportType';
import {
    ReportTypeFromJSON,
    ReportTypeFromJSONTyped,
    ReportTypeToJSON,
} from './ReportType';

/**
 * 
 * @export
 * @interface ReportRequest
 */
export interface ReportRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportRequest
     */
    resourceIdentifier: string;
    /**
     * 
     * @type {ReportReason}
     * @memberof ReportRequest
     */
    reason: ReportReason;
    /**
     * 
     * @type {ReportType}
     * @memberof ReportRequest
     */
    reportType: ReportType;
    /**
     * 
     * @type {string}
     * @memberof ReportRequest
     */
    readonly resourceId?: string;
}

/**
 * Check if a given object implements the ReportRequest interface.
 */
export function instanceOfReportRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "resourceIdentifier" in value;
    isInstance = isInstance && "reason" in value;
    isInstance = isInstance && "reportType" in value;

    return isInstance;
}

export function ReportRequestFromJSON(json: any): ReportRequest {
    return ReportRequestFromJSONTyped(json, false);
}

export function ReportRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resourceIdentifier': json['resourceIdentifier'],
        'reason': ReportReasonFromJSON(json['reason']),
        'reportType': ReportTypeFromJSON(json['reportType']),
        'resourceId': !exists(json, 'resourceId') ? undefined : json['resourceId'],
    };
}

export function ReportRequestToJSON(value?: ReportRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resourceIdentifier': value.resourceIdentifier,
        'reason': ReportReasonToJSON(value.reason),
        'reportType': ReportTypeToJSON(value.reportType),
    };
}

