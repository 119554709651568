import { Outlet } from 'react-router-dom'
import { HomeNavigation } from '../home/HomeNavigation'

export const HomePageLayout: React.FC = () => {
  return (
    <div>
      <HomeNavigation />
      <Outlet />
    </div>
  )
}
