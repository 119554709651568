import { create, props } from '@stylexjs/stylex'
import { Outlet } from 'react-router-dom'
import { spacing } from '../styles/token.stylex'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  container: {
    padding: {
      default: spacing.normal,
      [largeScreen]: `${spacing.extraLarge} ${spacing.extraLarger}`,
    },
  },
})

export const PageLayout: React.FC = () => {
  return (
    <div {...props(styles.container)}>
      <Outlet />
    </div>
  )
}
