import { useMutation, useQueryClient } from 'react-query'
import { useApi } from '../../api/ApiContext'
import { toast } from 'react-toastify'
import useLanguage from '../../shared/stores/useLanguage'
import { AddPostRequest, GetPostResponse } from '../../client'
import { useErrorNotification } from '../../translations/useErrorNotification'
import { queryKeys } from '../../constants/query-keys'
import { useNavigate } from 'react-router-dom'

export const useCreatePost = () => {
  const { postApi } = useApi()
  const { t } = useLanguage()
  const queryClient = useQueryClient()
  const notifyError = useErrorNotification()
  const navigate = useNavigate()

  return useMutation<GetPostResponse, Error, AddPostRequest>({
    mutationFn: (addPostRequest) => postApi.postPost({ addPostRequest }),
    onSuccess: () => {
      toast.success(t('feeds.CreatePostSuccessSnackbar'))
      queryClient.invalidateQueries(queryKeys.posts.default)
      navigate('/')
    },
    onError: notifyError(() => {
      toast.error(t('feeds.CreatePostErrorSnackbar'))
    }),
  })
}
