/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaymentPageSession } from './PaymentPageSession';
import {
    PaymentPageSessionFromJSON,
    PaymentPageSessionFromJSONTyped,
    PaymentPageSessionToJSON,
} from './PaymentPageSession';

/**
 * Represents the response containing the payment page URL.
 * @export
 * @interface DepositResponse
 */
export interface DepositResponse {
    /**
     * 
     * @type {PaymentPageSession}
     * @memberof DepositResponse
     */
    paymentPageSession?: PaymentPageSession;
}

/**
 * Check if a given object implements the DepositResponse interface.
 */
export function instanceOfDepositResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DepositResponseFromJSON(json: any): DepositResponse {
    return DepositResponseFromJSONTyped(json, false);
}

export function DepositResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DepositResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paymentPageSession': !exists(json, 'paymentPageSession') ? undefined : PaymentPageSessionFromJSON(json['paymentPageSession']),
    };
}

export function DepositResponseToJSON(value?: DepositResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paymentPageSession': PaymentPageSessionToJSON(value.paymentPageSession),
    };
}

