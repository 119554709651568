export const queryKeywords = {
  channel: 'channel',
  user: 'user',
  me: 'channel-me',
  posts: 'posts',
  payments: 'payments',
  payouts: 'payouts',
  stream: 'stream',
  promoPanel: 'promoPanel',
  mentionUsers: 'mention-users',
  // API Sandbox
  sandboxDefaultError: 'default-error',
  sandboxNotifyEmptyError: 'notify-empty-error',
  sandboxNotifyCallbackError: 'notify-callback-error',
  sandboxCustomError: 'custom-error',
}

export const queryKeys = {
  user: {
    default: [queryKeywords.user],
  },
  channel: {
    default: [queryKeywords.channel],
    slug: (slug: string) => [queryKeywords.channel, slug],
    me: (slug: string) => [queryKeywords.channel, queryKeywords.me, slug],
  },
  stream: {
    default: [queryKeywords.stream],
  },
  posts: {
    default: [queryKeywords.posts],
  },
  promoPanel: {
    default: (channelId: string) => [queryKeywords.promoPanel, channelId],
  },
  payments: {
    default: [queryKeywords.payments],
  },
  payouts: {
    default: [queryKeywords.payouts],
  },
  mentionUsers: {
    default: (query: string) => [queryKeywords.mentionUsers, query],
  },
  apiSandbox: {
    default: (code?: string) => [queryKeywords.sandboxDefaultError, code],
    notifyCallbackError: (code?: string) => [
      queryKeywords.sandboxNotifyCallbackError,
      code,
    ],
    customError: (code?: string) => [queryKeywords.sandboxCustomError, code],
  },
}
