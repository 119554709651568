import { Navigate, Outlet } from 'react-router-dom'
import { routes } from './routes'
import { useUserContext } from '../auth/user-context'

export const CreatorRoute: React.FC = () => {
  const { data } = useUserContext()
  const userIsCreator = data?.isContentCreator

  return userIsCreator ? (
    <Outlet />
  ) : (
    <Navigate to={routes.becomeACreator} replace />
  )
}
