/* eslint-disable sonarjs/cognitive-complexity */
import { create, props } from '@stylexjs/stylex'
import { Link } from 'react-router-dom'
import { color } from '../styles/token.stylex'
import { fonts, titleFonts } from '../styles/font-styles'
import { MouseEventHandler, PropsWithChildren } from 'react'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  container: {
    textDecoration: 'none',
    cursor: 'pointer',
    color: {
      default: color.textLinkColorDefault,
      ':active': color.textLinkColorActive,
      [largeScreen]: {
        ':hover': color.textLinkColorHover,
      },
    },
  },
  footer: {
    color: {
      default: color.textLinkColorFooterDefault,
      ':hover': color.textLinkColorHover,
      ':active': color.textLinkColorActive,
    },
  },
  disabled: {
    color: color.textLinkColorDisabled,
    cursor: 'not-allowed',
    pointerEvents: 'none',
  },
  underline: {
    textDecoration: {
      default: 'none',
      ':hover': 'underline',
    },
  },
  white: {
    color: {
      default: color.textLinkWhiteVariantDefault,
      ':hover': color.textLinkWhiteVariantHovered,
    },
  },
  gray: {
    color: {
      default: color.textLinkGrayVariantDefault,
      ':hover': color.textLinkGrayVariantHovered,
    },
  },
})

type TextLinkProps = {
  disabled?: boolean
  to?: string
  isExternal?: boolean
  font?: keyof typeof fonts
  titleFont?: keyof typeof titleFonts
  onClick?: MouseEventHandler<HTMLAnchorElement | HTMLSpanElement>
  variant?: 'white' | 'gray' | 'footer'
  underline?: boolean
}

export const TextLink: React.FC<PropsWithChildren<TextLinkProps>> = ({
  disabled = false,
  to,
  isExternal = false,
  font = 'smallRegular',
  titleFont,
  onClick,
  children,
  variant,
  underline,
}) => {
  if (disabled || !(to || onClick)) {
    return (
      <span
        {...props(
          styles.container,
          !titleFont && fonts[font],
          titleFont && titleFonts[titleFont],
          styles.disabled,
          variant && styles[variant]
        )}
      >
        {children}
      </span>
    )
  }
  if (to) {
    return (
      <Link
        to={to}
        onClick={onClick}
        target={isExternal ? '_blank' : undefined}
        rel={isExternal ? 'noopener noreferrer' : undefined}
        {...props(
          styles.container,
          !titleFont && fonts[font],
          titleFont && titleFonts[titleFont],
          variant && styles[variant],
          underline && styles.underline
        )}
      >
        {children}
      </Link>
    )
  }

  return (
    <span
      onClick={onClick}
      {...props(
        styles.container,
        !titleFont && fonts[font],
        titleFont && titleFonts[titleFont],
        variant && styles[variant]
      )}
    >
      {children}
    </span>
  )
}
