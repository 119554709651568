/** The first section of routes (this shows that these are not channels) */

import { RESERVED_WORDS } from '../constants/reserved-words'

export const routes = {
  home: '/',
  streamsHome: `/${RESERVED_WORDS.streams}`,
  login: `/${RESERVED_WORDS.login}`,
  logout: `/${RESERVED_WORDS.logout}`,
  signup: `/${RESERVED_WORDS.signUp}`,
  feeds: `/${RESERVED_WORDS.feeds}`,
  post: {
    create: `/${RESERVED_WORDS.post}/${RESERVED_WORDS.create}`,
    id: (id: string) => `/${RESERVED_WORDS.post}/${id}`,
  },
  auth: {
    callback: `/${RESERVED_WORDS.callback}`,
  },
  styleGuide: `/${RESERVED_WORDS.styleGuide}`,
  profile: `/${RESERVED_WORDS.profile}`,
  profileRoutes: {
    myStream: `/${RESERVED_WORDS.profile}/my-stream`,
    myProfile: `/${RESERVED_WORDS.profile}/my-profile`,
    streamKey: `/${RESERVED_WORDS.profile}/stream-key`,
    subscriptionPrice: `/${RESERVED_WORDS.profile}/subscription-price`,
    paymentHistory: `/${RESERVED_WORDS.profile}/payment-history`,
    earnings: `/${RESERVED_WORDS.profile}/earnings`,
  },
  channel: {
    ...getChannelPaths('home', (slug: string) => `/${slug}`),
    ...getChannelPaths(
      'video',
      (slug: string, videoId: string) => `/${slug}/videos/${videoId}`
    ),
  },
  communityGuidelines: `/${RESERVED_WORDS.communityGuidelines}`,
  termsOfService: `/${RESERVED_WORDS.termsOfService}`,
  dmcaPolicy: `/${RESERVED_WORDS.dmcaPolicy}`,
  privacyPolicy: `/${RESERVED_WORDS.privacyPolicy}`,
  howToEmbed: `/${RESERVED_WORDS.howToEmbed}`,
  bank: `/${RESERVED_WORDS.bank}`,
  payoutRequest: `/${RESERVED_WORDS.payoutRequest}`,
  becomeACreator: `/${RESERVED_WORDS.becomeACreator}`,
  helpAndSupport: `/${RESERVED_WORDS.helpAndSupport}`,
  payments: `/${RESERVED_WORDS.payments}`,
}

/**
 * It creates these variants:
 *
 * - fooShort: the function that you give;
 * - fooFull: (slug: string, videoId: string) => `channel/${shortVersion}`;
 * - foo: fooShort or fooFull depending on the slug
 *
 * @param short the function for the short version which is used to create the others
 */
function getChannelPaths<
  TName extends string,
  T extends (slug: string, ...args: string[]) => string,
>(name: TName, short: T) {
  const words = RESERVED_WORDS as Record<string, string>
  return {
    [`${name}Short`]: short,
    [`${name}Full`]: ((slug, ...args) =>
      `/channel${short(slug, ...args)}`) as T,
    [name]: ((slug, ...args) => {
      if (words[slug] !== undefined) return `/channel${short(slug, ...args)}`
      return short(slug, ...args)
    }) as T,
  } as ChannelPaths<TName, T>
}

type ChannelPaths<
  TName extends string,
  T extends (slug: string, ...args: string[]) => string,
> = {
  [K in `${TName}Short` | `${TName}Full` | TName]: T
}
