import React from 'react'
import { create, props } from '@stylexjs/stylex'
import { Skeleton } from '../components/Skeleton'
import { color, fontSize, spacing } from '../styles/token.stylex'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.extraLarge,
    maxWidth: '888px',
    margin: 'auto',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.normal,
    padding: spacing.normal,
    borderRadius: spacing.extraSmall,
    backgroundColor: color.deepGray,
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
  },
  title: {
    fontSize: {
      default: fontSize.large,
      [largeScreen]: fontSize.xxl,
    },
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    justifyContent: 'space-between',
  },
  headerLeftWrapper: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
  avatar: {
    width: 32,
    height: 32,
    borderRadius: '50%',
  },
  creator: {
    width: 100,
    height: 16,
    borderRadius: '4px',
  },
  date: {
    width: 50,
    height: 16,
  },
  text: {
    width: '100%',
    height: 16,
  },
  image: {
    height: 224,
  },
  footer: {
    display: 'flex',
    gap: spacing.normal,
  },
  icon: {
    width: 16,
    height: 16,
  },
})

type PostCardPlaceholderProps = {
  title?: string
  count?: number
}

export const PostCardPlaceholder: React.FC<PostCardPlaceholderProps> = ({
  title,
  count = 1,
}) => {
  return (
    <div {...props(styles.container)}>
      {title && <h1 {...props(styles.title)}>{title}</h1>}
      {Array.from({ length: count }, (_, i) => (
        <div key={i} {...props(styles.contentContainer)}>
          <div {...props(styles.headerContainer)}>
            <div {...props(styles.headerLeftWrapper)}>
              <Skeleton type="text" style={styles.avatar} />
              <Skeleton type="text" style={styles.creator} />
            </div>
            <Skeleton type="text" style={styles.date} />
          </div>
          <div>
            <Skeleton type="text" style={styles.text} />
            <Skeleton type="text" style={styles.text} />
            <Skeleton type="text" style={styles.text} />
          </div>
          <Skeleton type="image" style={styles.image} />
          <div {...props(styles.footer)}>
            <Skeleton type="text" style={styles.text} />
          </div>
        </div>
      ))}
    </div>
  )
}
