import React from 'react'
import { Link } from 'react-router-dom'
import { create, props } from '@stylexjs/stylex'
import { color } from '../../styles/token.stylex'
import DefaultProfilePicture from '../../../public/default-profile-image.png'

const styles = create({
  container: {
    borderRadius: '50%',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: color.avatarImageBackground,
    width: '1.5rem',
    height: '1.5rem',
    transition: 'transform 0.3s ease',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  medium: {
    width: '2.625rem',
    height: '2.625rem',
  },
  large: {
    width: '4.5rem',
    height: '4.5rem',
  },
  small: {
    width: '2.06rem',
    height: '2.06rem',
  },
  xsmall: {
    width: '1.8rem',
    height: '1.8rem',
  },
  xlarge: {
    width: '6rem',
    height: '6rem',
  },
  border: {
    borderStyle: 'solid',
    borderWidth: '2px',
    borderRadius: '50%',
    borderColor: color.avatarImageBorder,
  },
  avatar: {
    transform: {
      default: 'scale(1)',
      ':hover': 'scale(1.05)',
    },
  },
})

export type AvatarImageSizeVariant =
  | 'xlarge'
  | 'large'
  | 'medium'
  | 'small'
  | 'xsmall'

type AvatarImageProps = {
  src?: string
  alt: string
  variant?: AvatarImageSizeVariant
  url?: string
  border?: boolean
}

export const AvatarImage: React.FC<AvatarImageProps> = ({
  src = DefaultProfilePicture,
  alt,
  variant,
  border,
  url,
}) => {
  const avatar = (
    <div
      {...props(
        styles.container,
        variant && styles[variant],
        url ? styles.avatar : undefined
      )}
    >
      <img
        src={src}
        alt={alt}
        {...props(styles.image, border && styles.border)}
      />
    </div>
  )

  return url ? <Link to={url}>{avatar}</Link> : avatar
}
