/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaymentMethodResponse } from './PaymentMethodResponse';
import {
    PaymentMethodResponseFromJSON,
    PaymentMethodResponseFromJSONTyped,
    PaymentMethodResponseToJSON,
} from './PaymentMethodResponse';

/**
 * Represents the response containing available payment methods and VAT calculations.
 * @export
 * @interface GetPaymentMethodsVatResponse
 */
export interface GetPaymentMethodsVatResponse {
    /**
     * List of available payment methods.
     * @type {Array<PaymentMethodResponse>}
     * @memberof GetPaymentMethodsVatResponse
     */
    paymentMethods: Array<PaymentMethodResponse>;
}

/**
 * Check if a given object implements the GetPaymentMethodsVatResponse interface.
 */
export function instanceOfGetPaymentMethodsVatResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "paymentMethods" in value;

    return isInstance;
}

export function GetPaymentMethodsVatResponseFromJSON(json: any): GetPaymentMethodsVatResponse {
    return GetPaymentMethodsVatResponseFromJSONTyped(json, false);
}

export function GetPaymentMethodsVatResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPaymentMethodsVatResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paymentMethods': ((json['paymentMethods'] as Array<any>).map(PaymentMethodResponseFromJSON)),
    };
}

export function GetPaymentMethodsVatResponseToJSON(value?: GetPaymentMethodsVatResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paymentMethods': ((value.paymentMethods as Array<any>).map(PaymentMethodResponseToJSON)),
    };
}

