import { toast } from 'react-toastify'
import useLanguage from '../shared/stores/useLanguage'
import { getErrorMessage } from '../api/apiError'

export const useErrorNotification = () => {
  const { t } = useLanguage()

  return (callback: (error: unknown) => void) =>
    (error: unknown): void => {
      toast.error(t(getErrorMessage(error)))
      callback(error)
    }
}
