import React, { useRef, useState } from 'react'
import { PostCard } from './PostCard'
import { replaceLinksWithAnchors } from '../shared/utils/LinkUtils'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { useUserContext } from '../auth/user-context'
import { GetPostResponse } from './../client/models/GetPostResponse'
import { useMutation, useQueryClient } from 'react-query'
import { useApi } from '../api/ApiContext'
import { Slides } from './slides/Slides'
import useLanguage from '../shared/stores/useLanguage'
import { queryKeys } from '../constants/query-keys'
import { toast } from 'react-toastify'
import { Dialog } from '../components/dialog/Dialog'
import { ReportingModalContent } from './ReportModalContent'

type SmartPostCardProps = {
  post: GetPostResponse
}

type LikeState = {
  isLiked: boolean
  likesCount: number
  likeInProgress: boolean
  onLike?: () => void
}

export const SmartPostCard: React.FC<SmartPostCardProps> = ({ post }) => {
  const { data } = useUserContext()
  const { postApi } = useApi()
  const { t } = useLanguage()
  const confirmDialogRef = useRef<HTMLDialogElement>(null)
  const reportDialogRef = useRef<HTMLDialogElement>(null)
  const queryClient = useQueryClient()

  const [likeState, setLikeState] = useState<LikeState>({
    isLiked: post.isLiked,
    likesCount: post.likeCount,
    likeInProgress: false,
    onLike: () => mutation.mutate(),
  })

  const mutation = useMutation({
    mutationFn: () => postApi.postPostIdLikePost({ postId: post.postId }),
    onMutate: async () => {
      setLikeState((prev) => ({
        ...prev,
        isLiked: !prev.isLiked,
        likesCount: prev.isLiked ? prev.likesCount - 1 : prev.likesCount + 1,
        likeInProgress: true,
      }))
    },
    onSettled: () => {
      setLikeState((prev) => ({
        ...prev,
        likeInProgress: false,
      }))
    },
    onError: () => {
      setLikeState((prev) => ({
        ...prev,
        isLiked: !prev.isLiked,
        likesCount: prev.isLiked ? prev.likesCount - 1 : prev.likesCount + 1,
        likeInProgress: false,
      }))
    },
  })

  const { mutate: deletePost, isLoading } = useMutation({
    mutationFn: () => postApi.postPostIdDelete({ postId: post.postId }),
    onSuccess: () => queryClient.invalidateQueries(queryKeys.posts.default),
    onSettled: () => confirmDialogRef.current?.close(),
    onError: () => {
      toast.error(t('post.postCard.postCardActions.delete.failed'))
    },
  })

  const contextMenuItems = [
    ...(data?.auth0Id === post.author.userId
      ? [
          {
            label: t('post.postCard.postCardActions.delete.title'),
            action: () => confirmDialogRef.current?.showModal(),
          },
        ]
      : []),
    ...(data
      ? [
          {
            label: t('post.postCard.postCardActions.report.title'),
            action: () => reportDialogRef.current?.showModal(),
          },
        ]
      : []),
  ]

  return (
    <div>
      <PostCard
        showFooter={!!data}
        onLike={likeState.onLike}
        likesCount={likeState.likesCount}
        isLiked={likeState.isLiked}
        likeInProgress={likeState.likeInProgress}
        creator={{
          displayName: post.author.name,
          profilePictureUrl: post.author.profilePicture?.source,
          isVerified: post.author.isVerified,
          avatarImageSize: 'small',
          profileUrl: `/${post.author.slug}`,
        }}
        contextMenuItems={contextMenuItems}
        text={replaceLinksWithAnchors(post.text)}
        publishedAt={post.publishedAt!}
      >
        <Slides images={post.images ?? []} videos={post.videos ?? []} />
      </PostCard>
      <Dialog
        ref={confirmDialogRef}
        title={t('post.postCard.postCardActions.delete.title')}
        description={t('post.postCard.postCardActions.delete.message')}
        cancel={{
          label: t('shared.buttons.cancel'),
          action: () => confirmDialogRef.current?.close(),
        }}
        ok={{
          label: t('shared.buttons.confirm'),
          action: () => {
            deletePost()
          },
          isLoading: isLoading,
        }}
        variant="dual"
      />
      <Dialog
        ref={reportDialogRef}
        title={t('post.postCard.postCardActions.report.title')}
        showCloseButton={true}
        cancel={{
          label: t('shared.buttons.cancel'),
          action: () => reportDialogRef.current?.close(),
        }}
      >
        <ReportingModalContent
          onClose={() => reportDialogRef.current?.close()}
          resourceId={post.postId}
          reportType="post"
        />
      </Dialog>
    </div>
  )
}
