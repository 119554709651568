export const RESERVED_WORDS = {
  profile: 'profile',
  streams: 'streams',
  communityGuidelines: 'community-guidelines',
  termsOfService: 'terms-of-service',
  dmcaPolicy: 'dmca-policy',
  privacyPolicy: 'privacy-policy',
  howToEmbed: 'how-to-embed',
  callback: 'callback',
  auth: 'auth',
  feeds: 'feeds',
  login: 'login',
  logout: 'logout',
  signUp: 'signup',
  styleGuide: 'style-guide',
  bank: 'bank',
  earnings: 'earnings',
  payoutRequest: 'payout-request',
  becomeACreator: 'become-a-creator',
  streamUrlKey: 'stream-url-key',
  helpAndSupport: 'help-and-support',
  payments: 'payments',
  post: 'post',
  create: 'create',
}
