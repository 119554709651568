import { createContext, useContext } from 'react'
import { GetUserInfoResponse } from '../client'

type UserContextProps = {
  isLoading: boolean
  isError: boolean
  data?: GetUserInfoResponse
  reloadUser: () => void
}

export const UserContext = createContext<UserContextProps>({
  isLoading: false,
  isError: false,
  reloadUser: () => {},
})

export const useUserContext = () => useContext(UserContext)
