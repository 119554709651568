import type { SVGProps } from 'react'

export const ContextMenuDots: React.FC<SVGProps<SVGElement>> = ({
  stroke = 'currentColor',
  height = '1.5rem',
  width = '1.5rem',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clipPath="url(#clip0_838_2367)">
      <path
        d="M4.16665 10.8334C4.62688 10.8334 4.99998 10.4603 4.99998 10C4.99998 9.53978 4.62688 9.16669 4.16665 9.16669C3.70641 9.16669 3.33331 9.53978 3.33331 10C3.33331 10.4603 3.70641 10.8334 4.16665 10.8334Z"
        fill={stroke}
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 10.8334C10.4603 10.8334 10.8334 10.4603 10.8334 10C10.8334 9.53978 10.4603 9.16669 10 9.16669C9.53978 9.16669 9.16669 9.53978 9.16669 10C9.16669 10.4603 9.53978 10.8334 10 10.8334Z"
        fill={stroke}
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8333 10.8334C16.2936 10.8334 16.6667 10.4603 16.6667 10C16.6667 9.53978 16.2936 9.16669 15.8333 9.16669C15.3731 9.16669 15 9.53978 15 10C15 10.4603 15.3731 10.8334 15.8333 10.8334Z"
        fill={stroke}
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_838_2367">
        <rect width="20" height="20" fill={stroke} />
      </clipPath>
    </defs>
  </svg>
)
