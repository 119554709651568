import React, {
  createContext,
  useContext,
  useMemo,
  PropsWithChildren,
} from 'react'
import {
  Configuration,
  Auth0Api,
  StreamApi,
  LivestreamsApi,
  ChannelsApi,
  VideoApi,
  UserApi,
  CategoryApi,
  SearchApi,
  ContentReportApi,
  ActivityFeedApi,
  PostApi,
  AssetApi,
  UploadApi,
  ErrorSandboxApi,
  OnboardingApi,
  PromoPanelApi,
  PaymentsApi,
  PayoutsApi,
} from '../client'
import { errorHandlerMiddleware } from './errorHandlingMiddleware'
import { createTokenMiddleware } from './tokenMiddleware'
import { useToken } from './useToken'

type ApiContextProps = {
  auth0Api: Auth0Api
  streamApi: StreamApi
  liveStreamsApi: LivestreamsApi
  channelsApi: ChannelsApi
  videoApi: VideoApi
  userApi: UserApi
  categoriesApi: CategoryApi
  searchApi: SearchApi
  reportApi: ContentReportApi
  activityFeedApi: ActivityFeedApi
  postApi: PostApi
  uploadApi: UploadApi
  assetApi: AssetApi
  errorApi: ErrorSandboxApi
  onboardingApi: OnboardingApi
  promoPanelApi: PromoPanelApi
  paymentsApi: PaymentsApi
  payoutsApi: PayoutsApi
}

const configuration = new Configuration({
  basePath: import.meta.env.VITE_API_BASE_URL,
  credentials: 'include',
})

const ApiContext = createContext<ApiContextProps | undefined>(undefined)

const ApiProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { getToken } = useToken()

  const tokenMiddleware = useMemo(
    () => createTokenMiddleware(getToken),
    [getToken]
  )

  const apis = useMemo(
    () => ({
      auth0Api: new Auth0Api(configuration)
        .withMiddleware(tokenMiddleware)
        .withMiddleware(errorHandlerMiddleware),
      streamApi: new StreamApi(configuration)
        .withMiddleware(tokenMiddleware)
        .withMiddleware(errorHandlerMiddleware),
      liveStreamsApi: new LivestreamsApi(configuration)
        .withMiddleware(tokenMiddleware)
        .withMiddleware(errorHandlerMiddleware),
      channelsApi: new ChannelsApi(configuration)
        .withMiddleware(tokenMiddleware)
        .withMiddleware(errorHandlerMiddleware),
      videoApi: new VideoApi(configuration)
        .withMiddleware(tokenMiddleware)
        .withMiddleware(errorHandlerMiddleware),
      userApi: new UserApi(configuration)
        .withMiddleware(tokenMiddleware)
        .withMiddleware(errorHandlerMiddleware),
      categoriesApi: new CategoryApi(configuration)
        .withMiddleware(tokenMiddleware)
        .withMiddleware(errorHandlerMiddleware),
      searchApi: new SearchApi(configuration)
        .withMiddleware(tokenMiddleware)
        .withMiddleware(errorHandlerMiddleware),
      reportApi: new ContentReportApi(configuration)
        .withMiddleware(tokenMiddleware)
        .withMiddleware(errorHandlerMiddleware),
      activityFeedApi: new ActivityFeedApi(configuration)
        .withMiddleware(tokenMiddleware)
        .withMiddleware(errorHandlerMiddleware),
      postApi: new PostApi(configuration)
        .withMiddleware(tokenMiddleware)
        .withMiddleware(errorHandlerMiddleware),
      uploadApi: new UploadApi(configuration)
        .withMiddleware(tokenMiddleware)
        .withMiddleware(errorHandlerMiddleware),
      assetApi: new AssetApi(configuration)
        .withMiddleware(tokenMiddleware)
        .withMiddleware(errorHandlerMiddleware),
      errorApi: new ErrorSandboxApi(configuration)
        .withMiddleware(tokenMiddleware)
        .withMiddleware(errorHandlerMiddleware),
      onboardingApi: new OnboardingApi(configuration)
        .withMiddleware(tokenMiddleware)
        .withMiddleware(errorHandlerMiddleware),
      promoPanelApi: new PromoPanelApi(configuration)
        .withMiddleware(tokenMiddleware)
        .withMiddleware(errorHandlerMiddleware),
      paymentsApi: new PaymentsApi(configuration)
        .withMiddleware(tokenMiddleware)
        .withMiddleware(errorHandlerMiddleware),
      payoutsApi: new PayoutsApi(configuration)
        .withMiddleware(tokenMiddleware)
        .withMiddleware(errorHandlerMiddleware),
    }),
    [tokenMiddleware]
  )

  return <ApiContext.Provider value={apis}>{children}</ApiContext.Provider>
}

const useApi = (): ApiContextProps => {
  const context = useContext(ApiContext)
  if (!context) throw new Error('useApi must be used within an ApiProvider')

  return context
}

export { ApiProvider, useApi }
