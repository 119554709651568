/* eslint-disable sonarjs/no-nested-functions */
import { useState } from 'react'
import { useFileValidator } from '../../shared/utils/FileUtils/useFileValidator'
import { useApi } from '../../api/ApiContext'
import * as tus from 'tus-js-client'
import { HttpResponse } from 'tus-js-client'
import { CloudflareResumableTusUploadResult } from '../../client'
import {
  validateAndCreateFile,
  ValidatedFile,
} from '../utils/fileValidationUtils'
import { nonNullable } from '../../shared/utils/typeUtils'
import { API_BASE_URL } from '../../shared/utils/constants'

export const useUploadHandler = () => {
  const fileValidator = useFileValidator()
  const { uploadApi } = useApi()
  const [fileData, setFileData] = useState<ValidatedFile[]>([])

  const uploadInProgress = fileData.some(
    (file) => file.progress !== undefined && file.progress < 100
  )

  const updateFileDataById = (
    id: string,
    updates: Partial<ValidatedFile>
  ): void => {
    setFileData((prevData) =>
      prevData.map((file) => (file.id === id ? { ...file, ...updates } : file))
    )
  }

  const handleUploadSuccess = (assetId: string, fileId: string) => {
    updateFileDataById(fileId, {
      assetId,
      isUploaded: true,
      progress: 100,
    })
  }

  const uploadFile = async (file: ValidatedFile) => {
    updateFileDataById(file.id, { progress: 0 })

    if (file.file.type.startsWith('video/')) {
      await startVideoUpload(file)
    } else {
      await startImageUpload(file)
    }
  }

  const startImageUpload = async (file: ValidatedFile) => {
    const simulateProgress = setInterval(() => {
      setFileData((prevData) =>
        prevData.map((f) =>
          f.id === file.id
            ? {
                ...f,
                progress:
                  f.progress !== undefined && f.progress < 100
                    ? f.progress + 10
                    : f.progress,
              }
            : f
        )
      )
    }, 800)

    try {
      const response = await uploadApi.uploadUploadImagePost({
        image: file.file,
      })

      if (response?.assetId) {
        handleUploadSuccess(response.assetId, file.id)
      } else {
        console.error(`Failed to upload image: ${file.file.name}`)
      }
    } catch (error) {
      console.error('Image upload failed:', error)
      updateFileDataById(file.id, {
        progress: undefined,
      })
    } finally {
      clearInterval(simulateProgress)
    }
  }

  const parseResponseBody = (
    res: HttpResponse
  ): CloudflareResumableTusUploadResult | undefined => {
    try {
      const responseBody = res.getBody()
      const parsedResponse = JSON.parse(
        responseBody
      ) as CloudflareResumableTusUploadResult
      return parsedResponse?.cloudflareId ? parsedResponse : undefined
    } catch (error) {
      console.error('Failed to parse response body:', error)
      return undefined
    }
  }

  const startVideoUpload = async (file: ValidatedFile) => {
    let cloudflareVideoId: string | undefined

    const options: tus.UploadOptions = {
      endpoint: `${API_BASE_URL}/upload/url`,
      chunkSize: 50 * 1024 * 1024,
      retryDelays: [0, 3000, 5000, 10000, 20000],
      metadata: {
        name: file.file.name,
        filetype: file.file.type,
        thumbnailTimestampPct: '0.5',
      },
      onError: (error) => {
        console.error('Video upload failed:', error)
        updateFileDataById(file.id, {
          progress: undefined,
        })
      },
      onProgress: (bytesUploaded, bytesTotal) => {
        const progress = Math.floor((bytesUploaded / bytesTotal) * 100)
        updateFileDataById(file.id, { progress })
      },
      onSuccess: async () => {
        updateFileDataById(file.id, { progress: 100 })

        if (!cloudflareVideoId) {
          console.error('Failed to determine Cloudflare Video ID')
          return
        }

        const uploadedVideoAsset =
          await uploadApi.uploadFinalizeUploadCloudflareVideoIdPost({
            cloudflareVideoId,
          })

        if (!uploadedVideoAsset.assetId) {
          console.error(`Failed to create video asset: ${cloudflareVideoId}`)
          return
        }
        handleUploadSuccess(uploadedVideoAsset.assetId, file.id)
      },
      onAfterResponse: (req, res) => {
        if (res.getStatus() === 200) {
          const parsedResponse = parseResponseBody(res)
          cloudflareVideoId = parsedResponse?.cloudflareId ?? undefined
        }
      },
    }

    const upload = new tus.Upload(file.file, options)
    upload.start()
  }

  const addFiles = async (acceptedFiles: File[]) => {
    const validatedFiles: ValidatedFile[] = (
      await Promise.all(
        acceptedFiles.map((file) => validateAndCreateFile(file, fileValidator))
      )
    ).filter(nonNullable)

    setFileData((prevData) => [...prevData, ...validatedFiles])

    validatedFiles.forEach(uploadFile)
  }

  const removeFile = (id: string) => {
    const fileToRemove = fileData.find((file) => file.id === id)
    if (fileToRemove) URL.revokeObjectURL(fileToRemove.url)
    setFileData((prevData) => prevData.filter((file) => file.id !== id))
  }

  const clearFiles = () => {
    fileData.forEach((file) => URL.revokeObjectURL(file.url))
    setFileData([])
  }

  return {
    fileData,
    addFiles,
    removeFile,
    clearFiles,
    uploadInProgress,
    uploadFile,
    updateFileDataById,
  }
}
