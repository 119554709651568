import { ErrorCodes } from '../client'
import { Middleware, ResponseContext } from '../client/runtime'
import { ApiError } from './apiError'
import { isModelErrorResponse } from './utils'

const toApiError = (message: string): ApiError =>
  new ApiError({
    code: ErrorCodes.TechnicalError,
    message,
  })

const readError = async (response: Response): Promise<unknown> => {
  try {
    const contentType = response.headers.get('content-type')
    if (contentType?.includes('application/json')) {
      return await response.json()
    }

    return await response.text()
  } catch (error) {
    console.error('Error parsing response:', error)

    throw toApiError('Error parsing response')
  }
}

export const errorHandlerMiddleware: Middleware = {
  post: async ({ response }: ResponseContext) => {
    if (response.status >= 400) {
      const error = await readError(response)

      if (typeof error === 'string') throw toApiError(error)
      if (
        error &&
        typeof error === 'object' &&
        'error' in error &&
        isModelErrorResponse(error.error)
      )
        throw new ApiError(error.error)

      throw toApiError('Unknown error type')
    }
  },
}
