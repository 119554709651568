import React from 'react'
import { SwiperSlide } from 'swiper/react'
import { Item } from 'react-photoswipe-gallery'
import { props } from '@stylexjs/stylex'
import { styles } from './slidesStyles'

type ImageSlideProps = {
  source: string
  width: number
  height: number
}

export const ImageSlide: React.FC<ImageSlideProps> = ({
  source,
  width,
  height,
}) => (
  <SwiperSlide {...props(styles.slideContainer)}>
    <Item original={source} thumbnail={source} width={width} height={height}>
      {({ ref, open }) => (
        <div {...props(styles.fullScreenImageContainer)} onClick={open}>
          <img
            ref={ref}
            src={source}
            alt="Post"
            {...props(styles.image)}
            onContextMenu={(e) => e.preventDefault()}
          />
          <img src={source} alt="Post" {...props(styles.backgroundBlurImage)} />
        </div>
      )}
    </Item>
  </SwiperSlide>
)

ImageSlide.displayName = 'SwiperSlide'
