import { create, props } from '@stylexjs/stylex'
import { useState, useEffect, useRef } from 'react'
import { fonts } from '../styles/font-styles'
import useLanguage from '../shared/stores/useLanguage'
import { TextLink } from './TextLink'

const styles = create({
  textWrapper: {
    position: 'relative',
  },
  textContent: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    hyphens: 'auto',
  },
  expandedText: {
    display: 'block',
    wordBreak: 'break-word',
  },
  lineClamp: (maxLines: number) => ({
    WebkitLineClamp: maxLines,
  }),
})

type ExpandableTextProps = {
  text: React.ReactNode
  maxLines?: number
}

const checkIfTruncated = (element: HTMLElement | null): boolean => {
  if (!element) return false
  return element.scrollHeight > element.clientHeight
}

export const ExpandableText: React.FC<ExpandableTextProps> = ({
  text,
  maxLines = 3,
}) => {
  const { t } = useLanguage()
  const [isExpanded, setIsExpanded] = useState(false)
  const [isTruncated, setIsTruncated] = useState(false)
  const textRef = useRef<HTMLDivElement>(null)

  const toggleText = () => setIsExpanded((prev) => !prev)

  useEffect(() => {
    const handleResize = () => {
      const truncated = checkIfTruncated(textRef.current)
      setIsTruncated(truncated)
      setIsExpanded(false)
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <div {...props(styles.textWrapper, fonts.smallRegular)}>
      <div
        ref={textRef}
        {...props(
          isExpanded
            ? styles.expandedText
            : [styles.textContent, styles.lineClamp(maxLines)]
        )}
      >
        {text}
      </div>
      {isTruncated && (
        <TextLink onClick={toggleText} font="extraSmallRegular">
          {isExpanded ? t('shared.text.showLess') : t('shared.text.readMore')}
        </TextLink>
      )}
    </div>
  )
}
