export const getCroppedImg = async (
  imageSrc: string,
  crop: { x: number; y: number; width: number; height: number },
  zoom: number = 1
): Promise<string> => {
  const image = new Image()
  image.src = imageSrc

  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  return new Promise((resolve, reject) => {
    image.onload = () => {
      const scaleX = image.width / image.naturalWidth
      const scaleY = image.height / image.naturalHeight

      canvas.width = crop.width * zoom
      canvas.height = crop.height * zoom

      if (ctx) {
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          canvas.width,
          canvas.height
        )
      }

      canvas.toBlob(
        (blob) => {
          if (blob) {
            resolve(URL.createObjectURL(blob))
          } else {
            reject(new Error('Canvas is empty'))
          }
        },
        'image/jpeg',
        1
      )
    }
    image.onerror = reject
  })
}
