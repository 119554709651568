/* eslint-disable sonarjs/media-has-caption */
import { useLayoutEffect, useMemo, useRef, useState } from 'react'
import videojs from 'video.js'
import 'video.js/dist/video-js.min.css'
import 'videojs-hotkeys'
import { styles } from './VideoPlayerStyles'
import { props } from '@stylexjs/stylex'

type PlayerOptions = typeof videojs.options

export type IVideoPlayerProps = {
  options: PlayerOptions
  previewMode?: boolean
}

type VideoPlayerType = ReturnType<typeof videojs>

const initialOptions: PlayerOptions = {
  controls: true,
  autoplay: false,
  fluid: true,
  controlBar: {
    currentTimeDisplay: true,
    timeDivider: true,
    durationDisplay: true,
    volumePanel: {
      inline: false,
      volumeControl: {
        vertical: true,
      },
    },
  },
  plugins: {
    hotkeys: {
      volumeStep: 0.1,
      seekStep: 5,
      enableModifiersForNumbers: false,
      enableHoverScroll: true,
      fullscreenKey: (event: KeyboardEvent) => {
        return event.key === 'F' || (event.ctrlKey && event.key === 'Enter')
      },
    },
  },
  html5: {
    iosNativeFullscreen: true,
  },
}

export const VideoPlayer: React.FC<IVideoPlayerProps> = ({
  options,
  previewMode = false,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const playerRef = useRef<VideoPlayerType>()
  const [isVideoPlayerReady, setIsVideoPlayerReady] = useState(false)
  const [isFullscreen, setIsFullscreen] = useState(false)

  useMemo(() => {
    if (!videoRef.current || !isVideoPlayerReady || playerRef.current) return

    playerRef.current = videojs(videoRef.current, {
      ...initialOptions,
      ...options,
    }) as VideoPlayerType

    const player = playerRef.current

    const handleFullscreenChange = () => {
      const fullscreenState = player.isFullscreen()
      setIsFullscreen(fullscreenState || false)
      console.log('Fullscreen state changed:', fullscreenState)
    }

    const handleClick = () => {
      if (previewMode) {
        if (!player.isFullscreen()) {
          player.requestFullscreen()
        }
      }
    }

    if (previewMode) {
      player.on('click', handleClick)
      player.on('fullscreenchange', handleFullscreenChange)
      player.on('touchstart', handleClick)
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoRef.current, playerRef.current, isVideoPlayerReady])

  useLayoutEffect(() => {
    if (videoRef.current) {
      setIsVideoPlayerReady(true)
      videoRef.current.classList.add('vjs-waiting')
    }
  }, [])

  return (
    <div
      {...props(styles.video, !isFullscreen && previewMode && styles.preview)}
    >
      <video
        ref={videoRef}
        className="video-js vjs-show-big-play-button-on-pause"
      />
    </div>
  )
}
