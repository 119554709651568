import { v4 as uuidv4 } from 'uuid'
import { getImageSize } from 'react-image-size'
import { FileValidator } from '../../shared/utils/FileUtils/useFileValidator'

export type ValidatedFile = {
  id: string
  file: File
  isUploaded: boolean
  url: string
  dimensions: { width: number; height: number }
  progress?: number
  assetId?: string
}

export const allowedTypes = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/heif',
  'video/mp4',
  'video/quicktime',
  'video/x-msvideo',
  'video/x-matroska',
  'video/webm',
]

export const validateAndCreateFile = async (
  file: File,
  fileValidator: FileValidator
): Promise<ValidatedFile | undefined> => {
  const isImage = file.type.startsWith('image/')
  const validationResult = await fileValidator.validateFile(file, {
    maxSizeMB: 1000,
    minWidth: isImage ? 128 : undefined,
    minHeight: isImage ? 128 : undefined,
    maxWidth: isImage ? 5000 : undefined,
    maxHeight: isImage ? 5000 : undefined,
    allowedTypes: allowedTypes,
  })

  const url = URL.createObjectURL(file)
  let dimensions = { width: 0, height: 0 }

  if (isImage) {
    try {
      dimensions = await getImageSize(url)
    } catch (e) {
      console.error('Failed to load image size:', e)
    }
  }

  return validationResult
    ? {
        id: uuidv4(),
        file,
        url,
        dimensions,
        progress: undefined,
        isUploaded: false,
      }
    : undefined
}
