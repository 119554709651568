import { create, props } from '@stylexjs/stylex'
import { fontSize, spacing } from '../styles/token.stylex'
import useLanguage from '../shared/stores/useLanguage'
import { TextLink } from '../components/TextLink'
import { fonts } from '../styles/font-styles'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  title: {
    fontSize: {
      default: fontSize.large,
      [largeScreen]: fontSize.xxl,
    },
  },
  container: {
    padding: {
      default: spacing.normal,
      [largeScreen]: spacing.extraLarge,
    },
  },
})

export const HomePage: React.FC = () => {
  const { t } = useLanguage()
  return (
    <div>
      <div {...props(fonts.smallRegular, styles.container)}>
        {t('home.underConstruction.firstPart')}
        <TextLink to="https://app.clickup.com/t/86c0wzq4q" isExternal>
          {'https://app.clickup.com/t/86c0wzq4q '}
        </TextLink>
        {t('home.underConstruction.secondPart')}
      </div>
    </div>
  )
}

export default HomePage
