/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetTransactionResponsePagedApiResponse,
  GetWalletBalance,
} from '../models/index';
import {
    GetTransactionResponsePagedApiResponseFromJSON,
    GetTransactionResponsePagedApiResponseToJSON,
    GetWalletBalanceFromJSON,
    GetWalletBalanceToJSON,
} from '../models/index';

export interface PayoutsTestAddTransactionPostRequest {
    amount?: number;
}

export interface PayoutsTransactionsGetRequest {
    pageNumber?: number;
    pageSize?: number;
}

/**
 * 
 */
export class PayoutsApi extends runtime.BaseAPI {

    /**
     */
    async payoutsBalancesGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetWalletBalance>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/payouts/balances`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetWalletBalanceFromJSON(jsonValue));
    }

    /**
     */
    async payoutsBalancesGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetWalletBalance> {
        const response = await this.payoutsBalancesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Adds a test transaction to a user\'s wallet.  This endpoint is for testing purposes only.
     */
    async payoutsTestAddTransactionPostRaw(requestParameters: PayoutsTestAddTransactionPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.amount !== undefined) {
            queryParameters['amount'] = requestParameters.amount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/payouts/test/add-transaction`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Adds a test transaction to a user\'s wallet.  This endpoint is for testing purposes only.
     */
    async payoutsTestAddTransactionPost(requestParameters: PayoutsTestAddTransactionPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.payoutsTestAddTransactionPostRaw(requestParameters, initOverrides);
    }

    /**
     * Creates a wallet for a user.  This endpoint is intended for testing purposes only and should not be used in production.
     */
    async payoutsTestCreateWalletPostRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/payouts/test/create-wallet`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Creates a wallet for a user.  This endpoint is intended for testing purposes only and should not be used in production.
     */
    async payoutsTestCreateWalletPost(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.payoutsTestCreateWalletPostRaw(initOverrides);
    }

    /**
     */
    async payoutsTransactionsGetRaw(requestParameters: PayoutsTransactionsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetTransactionResponsePagedApiResponse>> {
        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['PageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/payouts/transactions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTransactionResponsePagedApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async payoutsTransactionsGet(requestParameters: PayoutsTransactionsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetTransactionResponsePagedApiResponse> {
        const response = await this.payoutsTransactionsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
