import { Button } from '../components/Button'
import useLanguage from '../shared/stores/useLanguage'
import { Unlockable } from './types'

type UnlockButtonProps = {
  unlockable?: Unlockable
}

export const UnlockButton: React.FC<UnlockButtonProps> = ({ unlockable }) => {
  const { formatCurrency, t } = useLanguage()

  if (!unlockable) return <></>

  const { currency, onUnlock, price } = unlockable

  const formattedPrice = formatCurrency(price, { currency: currency })

  return (
    <Button onClick={onUnlock} variant="cta">
      {t('post.postCard.unlockButtonText', {
        price: formattedPrice,
      })}
    </Button>
  )
}
