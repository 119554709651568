import { create } from '@stylexjs/stylex'
import { color } from '../../styles/token.stylex'

export const styles = create({
  video: {
    width: '100%',
    height: '100%',
    ':not(#__) > div': {
      height: '100% !important',
    },
    ':not(#_) .vjs-menu': {
      left: 'unset !important',
      right: '0 !important',
    },
    ':not(#_) .vjs-control-bar': {
      height: '4em !important',
    },
    ':not(#_) .vjs-time-control': {
      fontSize: '1.1em !important',
    },
    ':not(#_) .vjs-button': {
      outline: 'none !important',
    },
    ':not(#_) .vjs-button > .vjs-icon-placeholder:before': {
      fontSize: '25px !important',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    ':not(#_) .vjs-control .vjs-progress-holder': {
      height: '3px !important',
    },
    ':not(#_) .vjs-control:hover': {
      color: `${color.primary} !important`,
    },
    ':not(#_) .vjs-volume-control .vjs-volume-bar': {
      width: '0.9em !important',
      justifyContent: 'center !important',
    },
    ':not(#_) .vjs-volume-control .vjs-volume-bar .vjs-volume-level': {
      width: '0.9em !important',
    },
    ':not(#_) .vjs-volume-control .vjs-volume-bar .vjs-volume-level:before': {
      left: '-0.15em !important',
      fontSize: '13px !important',
    },
    ':not(#_) .vjs-big-play-button': {
      borderRadius: '50% !important',
      top: '50% !important',
      left: '50% !important',
      transform: 'translate(-50%, -50%) !important',
      margin: '0 !important',
      height: '50px !important',
      width: '50px !important',
      borderWidth: '0 !important',
      backgroundColor: `${color.mineShaft} !important`,
    },
    ':not(#_) .vjs-big-play-button .vjs-icon-placeholder:before': {
      borderRadius: '50% !important',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ':hover': {
        color: color.white,
        backgroundColor: color.primary,
      },
    },
    ':not(#_) .vjs-remaining-time': {
      alignContent: 'center !important',
    },
  },
  preview: {
    ':not(#_) .vjs-control-bar': {
      display: 'none !important',
    },
    ':not(#__) > div': {
      paddingTop: '0 !important',
    },
  },
})
