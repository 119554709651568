/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DepositMethod = {
    NBDirectPayment: 'nB_DIRECT_PAYMENT'
} as const;
export type DepositMethod = typeof DepositMethod[keyof typeof DepositMethod];


export function DepositMethodFromJSON(json: any): DepositMethod {
    return DepositMethodFromJSONTyped(json, false);
}

export function DepositMethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): DepositMethod {
    return json as DepositMethod;
}

export function DepositMethodToJSON(value?: DepositMethod | null): any {
    return value as any;
}

