import { useState, useRef } from 'react'
import { ValidatedFile } from '../../OnlyStream/utils/fileValidationUtils'
import useLanguage from '../../shared/stores/useLanguage'
import { useDraggable } from 'react-use-draggable-scroll'
import { useUploadHandler } from '../../OnlyStream/hooks/useUploadHandler'
import { useDropzone } from 'react-dropzone'
import { LoadingIndicator } from '../../components/indicators/LoadingIndicator'
import { props } from '@stylexjs/stylex'
import { DashboardPanel } from '../../profile/panels/DashboardPanel'
import { styles } from './CreatePostStyles'
import { Media } from '../../components/icons/Media'
import { PlusWithoutCircle } from '../../components/icons/PlusWithoutCircle'
import { FilePreview } from './FilePreview'
import { ImageEditor } from './ImageEditor'
import { filterValidAssetIds, isPostValid, processCroppedImage } from './utils'
import { useCreatePost } from '../../OnlyStream/hooks/useCreatePost'
import { MentionsTextArea } from '../../components/input/MentionTextArea'
import useMentions from '../../OnlyStream/hooks/useMentions'
import { Tooltip } from '../../components/Tooltip'

export const CreatePost: React.FC = () => {
  const [postContent, setPostContent] = useState('')
  const [editingImage, setEditingImage] = useState<ValidatedFile>()
  const { t } = useLanguage()
  const ref =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>
  const { events } = useDraggable(ref)
  const createPost = useCreatePost()

  const {
    fileData,
    addFiles,
    removeFile,
    clearFiles,
    uploadInProgress,
    updateFileDataById,
    uploadFile,
  } = useUploadHandler()

  const { mentionUsers, handlePostContentChange } = useMentions(
    postContent,
    setPostContent
  )

  const handlePost = () => {
    if (!isPostValid(postContent, fileData)) return

    createPost.mutate({
      text: postContent,
      assetIds: filterValidAssetIds(fileData),
    })
    setPostContent('')
    clearFiles()
  }

  const handleSaveCrop = async (croppedImageUrl: string) => {
    if (!editingImage) return

    await processCroppedImage(
      croppedImageUrl,
      editingImage,
      updateFileDataById,
      uploadFile
    )

    setEditingImage(undefined)
  }

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: addFiles,
    noClick: true,
    noKeyboard: true,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.gif', '.heif'],
      'video/*': ['.mp4', '.mov', '.avi', '.mkv', '.webm'],
    },
  })

  if (createPost.isLoading) return <LoadingIndicator />

  return (
    <div>
      <DashboardPanel
        title={t('feeds.NewPost')}
        variant="createPost"
        actionButtons={{
          secondary: {
            label: t('feeds.Clear'),
            disabled: postContent === '' && fileData.length === 0,
            onClick: () => {
              setPostContent('')
              clearFiles()
            },
          },
          primary: {
            label: t('feeds.Post'),
            disabled:
              (postContent === '' && fileData.length === 0) || uploadInProgress,
            onClick: handlePost,
          },
        }}
      >
        <div
          {...props(
            styles.dropzoneArea,
            isDragActive && styles.dropzoneArea_dragActive
          )}
          {...getRootProps()}
        >
          <div
            {...props(
              styles.previewWrapper,
              fileData.length === 0 && styles.previewWrapper_hidden
            )}
            ref={ref}
            {...events}
          >
            <FilePreview
              fileData={fileData}
              removeFile={removeFile}
              onEditFile={setEditingImage}
            />
            <div onClick={open} {...props(styles.addButton)}>
              <Tooltip text={t('post.create.addMedia')}>
                <PlusWithoutCircle />
              </Tooltip>
            </div>
          </div>
          <input {...props(styles.hiddenInput)} {...getInputProps()} />
          <div {...props(styles.textAreaWrapper)}>
            <MentionsTextArea
              id="create-post-input"
              value={postContent}
              onChange={handlePostContentChange}
              mentionData={mentionUsers}
              placeholder={
                isDragActive
                  ? t('feeds.TextareaPlaceholderActiveDrag')
                  : t('feeds.TextareaPlaceholderInitial')
              }
              maxLength={500}
              required
            />
          </div>
          <div {...props(styles.actionButtonsWrapper)}>
            <div
              {...props(styles.mediaButton)}
              onClick={open}
              tabIndex={0}
              onKeyDown={(e) => e.key === 'Enter' && open()}
            >
              <Tooltip text={t('post.create.addMedia')}>
                <Media width={18} height={18} />
              </Tooltip>
            </div>
          </div>
        </div>
      </DashboardPanel>

      {editingImage && (
        <ImageEditor
          isOpen={!!editingImage}
          imageUrl={editingImage.url}
          onSave={handleSaveCrop}
          onClose={() => setEditingImage(undefined)}
        />
      )}
    </div>
  )
}
