import { FallbackProps } from 'react-error-boundary'
import { ErrorLayout } from '../ErrorLayout'
import useLanguage from '../../shared/stores/useLanguage'
import { props } from '@stylexjs/stylex'
import { fonts } from '../../styles/font-styles'
import { TextLink } from '../../components/TextLink'
import { errorPageStyles } from './error-page-styles'

export const GeneralErrorPage: React.FC<FallbackProps> = ({
  resetErrorBoundary,
}) => {
  const { t } = useLanguage()

  return (
    <ErrorLayout>
      <div {...props(errorPageStyles.container)}>
        <div {...props(fonts.normalSemiBold)}>
          {t('errors.general.technicalError')}
        </div>
        <div {...props(errorPageStyles.linkContainer)}>
          <TextLink onClick={resetErrorBoundary}>{t('general.retry')}</TextLink>
        </div>
      </div>
    </ErrorLayout>
  )
}
