import { create, props } from '@stylexjs/stylex'
import { color, spacing } from '../styles/token.stylex'
import { MouseEventHandler, SVGProps } from 'react'
import { ButtonLoader } from './icons/ButtonLoader'
import { Tooltip } from './Tooltip'

const styles = create({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: {
      default: 'pointer',
      ':disabled': 'default',
    },
    border: 'none',
    width: spacing.xxl,
    height: spacing.xxl,
    borderRadius: spacing.xxSmall,
    backgroundColor: 'transparent',
    color: {
      default: color.iconButtonColorDefault,
      ':hover:enabled': color.iconButtonColorHover,
      ':active': color.iconButtonColorActive,
      ':focus': color.iconButtonColorFocus,
      ':disabled': color.iconButtonColorDisabled,
    },
    outline: 'none',
    pointerEvents: {
      ':disabled': 'none',
    },
  },
  small: {
    width: spacing.large,
    height: spacing.large,
  },
  close: {
    width: spacing.large,
    height: spacing.large,
  },
  loader: {
    width: '1.125rem',
    height: '1.125rem',
    animation: 'spin 1s linear infinite',
  },
  white: {
    color: {
      default: color.white,
      ':hover:enabled': color.gold,
      ':active': color.gold,
      ':focus': color.gold,
    },
  },
})

type IconButtonProps = {
  variant?: 'small' | 'close'
  color?: 'white'
  disabled?: boolean
  isLoading?: boolean
  tooltip?: string
  icon: React.FC<SVGProps<SVGElement>>
  onClick?: MouseEventHandler<HTMLButtonElement>
}

export const IconButton: React.FC<IconButtonProps> = ({
  variant,
  disabled,
  isLoading,
  tooltip,
  icon: Icon,
  color,
  onClick,
}) => {
  if (isLoading) {
    return (
      <button disabled {...props(styles.container, variant && styles[variant])}>
        <div {...props(styles.loader)}>
          <ButtonLoader />
        </div>
      </button>
    )
  }

  const buttonContent = (
    <button
      {...props(
        styles.container,
        variant && styles[variant],
        color && styles[color]
      )}
      onClick={onClick}
      disabled={disabled}
    >
      <Icon />
    </button>
  )

  if (tooltip) {
    return <Tooltip text={tooltip}>{buttonContent}</Tooltip>
  }

  return buttonContent
}
